var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useLanguage } from '@planter/common';
import { AvailableLanguages } from '@planter/common/src/models/general.models';
import * as React from 'react';
import { Link } from 'react-router-dom';
import AppStoreIcon from '../../assets/AppStoreIcon.svg';
import AppStoreIconEn from '../../assets/AppStoreIconEn.svg';
import PlayStoreIcon from '../../assets/PlayStoreIcon.svg';
import PlayStoreIconEn from '../../assets/PlayStoreIconEn.svg';
import { Images } from '../../assets/images';
import { AppRoutes } from '../../routes/routes';
import PlMenu from '../PlMenu';
import PlText from '../PlText';
import FavoriteIcon, { IconFavoriteType } from '../icons/FavoriteIcon';
import PlanterBrandLogo from '../icons/PlanterBrandLogo';
import PlanterBrandLogoWhite from '../icons/PlanterBrandLogoWhite';
var DefaultLayout = function (_a) {
    var children = _a.children, lateralComponent = _a.lateralComponent, _b = _a.isMobileHidden, isMobileHidden = _b === void 0 ? true : _b;
    var _c = useLanguage(), t = _c[0], locale = _c[2];
    var isItalianLanguage = locale === AvailableLanguages.IT;
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "h-screen ".concat(isMobileHidden ? 'hidden md:block' : '') }, { children: [_jsx("div", __assign({ className: " border-b border-solid border-gray flex w-full fixed bg-white z-50" }, { children: _jsxs("div", __assign({ className: "max-w-screen-xl w-full mx-auto h-[80px] flex items-center justify-between px-6" }, { children: [_jsx("div", { children: _jsx(PlanterBrandLogo, {}) }), _jsx(Link, __assign({ to: AppRoutes.FavouritesPage }, { children: _jsxs("div", __assign({ className: "flex items-center justify-end" }, { children: [_jsx("div", __assign({ className: "rounded-full bg-grey-extra-light w-8 h-8 flex items-center justify-center" }, { children: _jsx(FavoriteIcon, { type: IconFavoriteType.FILLED }) })), _jsx("p", __assign({ className: "font-normal pl-2" }, { children: t('favorite') }))] })) }))] })) })), _jsx("div", __assign({ className: "w-full bg-grey-extra-light min-h-[calc(100vh-80px-1px)] pt-20" }, { children: _jsxs("div", __assign({ className: "max-w-screen-xl h-[calc(100vh-80px-1px)] mx-auto bg-white flex" }, { children: [_jsx(PlMenu, { className: "w-56 border-r border-solid border-gray" }), _jsx("div", __assign({ className: "flex-[3] h-full overflow-y-auto" }, { children: children })), lateralComponent && (_jsx("div", __assign({ className: "flex-1 border-l border-solid border-gray p-6 h-full overflow-y-auto" }, { children: lateralComponent })))] })) }))] })), isMobileHidden && (_jsxs("div", __assign({ className: "md:hidden h-screen flex flex-col p-6", style: {
                    backgroundImage: "url(".concat(Images.screenTooSmall, ")"),
                    backgroundSize: 'cover',
                } }, { children: [_jsx("div", __assign({ className: "h-[50%] pt-8" }, { children: _jsx(PlanterBrandLogoWhite, { size: "100px" }) })), _jsxs("div", __assign({ className: "flex flex-col justify-end h-[50%]" }, { children: [_jsx(PlText, { label: t('mobileViewWebInformation.dowloadApp'), className: "whitespace-pre-line text-white !text-[28px] leading-8 font-medium" }), _jsxs("div", __assign({ className: "my-8 flex items-center justify-between flex-wrap" }, { children: [_jsx("a", __assign({ href: process.env.REACT_APP_GOOGLE_PLAY_URL, target: "_blank", rel: "noreferrer" }, { children: _jsx("img", { src: isItalianLanguage ? PlayStoreIcon : PlayStoreIconEn, alt: t('PlayStoreIcon'), className: "hover:cursor-pointer h-[50px] w-[167px]" }) })), _jsx("a", __assign({ href: process.env.REACT_APP_APP_STORE_URL, target: "_blank", rel: "noreferrer" }, { children: _jsx("img", { src: isItalianLanguage ? AppStoreIcon : AppStoreIconEn, alt: t('AppStoreIcon'), className: "hover:cursor-pointer h-[50px] w-[148px]" }) }))] })), _jsx(Link, __assign({ to: AppRoutes.SubscriptionDetails }, { children: _jsx(PlText, { label: t('mobileViewWebInformation.manageSubscription'), className: "underline text-center font-medium text-white !text-[14px] leading-8" }) }))] }))] })))] }));
};
export default DefaultLayout;
