var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import { useCallback } from 'react';
export var ButtonType;
(function (ButtonType) {
    ButtonType["Primary"] = "primary";
    ButtonType["Secondary"] = "secondary";
    ButtonType["Tertiary"] = "tertiary";
})(ButtonType || (ButtonType = {}));
var PlButton = function (_a) {
    var label = _a.label, _b = _a.type, type = _b === void 0 ? ButtonType.Primary : _b, _c = _a.className, className = _c === void 0 ? '' : _c, _d = _a.isDisabled, isDisabled = _d === void 0 ? false : _d, onClick = _a.onClick, trackingId = _a.trackingId;
    var handleClick = useCallback(function () {
        if (onClick) {
            onClick();
        }
    }, [onClick]);
    var buttonTypeClass = React.useMemo(function () {
        var finalClasses = '';
        switch (type) {
            case ButtonType.Primary:
                if (isDisabled) {
                    finalClasses += 'text-grey bg-grey-light';
                }
                else {
                    finalClasses += 'text-white bg-black';
                }
                break;
            case ButtonType.Secondary:
                if (isDisabled) {
                    finalClasses +=
                        'text-grey-medium bg-white border-grey-medium border-solid border';
                }
                else {
                    finalClasses +=
                        '!text-black bg-white border-black border-solid border';
                }
                break;
            case ButtonType.Tertiary:
                finalClasses +=
                    '!text-black bg-transparent border-dashed border border-grey-medium';
                break;
        }
        return finalClasses;
    }, [isDisabled, type]);
    return (_jsx("button", __assign({ id: trackingId, onClick: !isDisabled ? handleClick : undefined, className: "rounded-2xl font-medium h-12 text-sm ".concat(buttonTypeClass, " px-3 flex items-center justify-center ").concat(className) }, { children: label })));
};
export default PlButton;
