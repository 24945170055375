var it = {
    //** GENERAL **//
    hello: 'Ciao mondo!',
    appName: 'Planter',
    appDescription: 'Il primo piano alimentare vegetale che ti semplifica la vita.',
    yes: 'Sì',
    no: 'No',
    ok: 'OK',
    feminineThe: 'la',
    masculineThe: 'il',
    masculinePluralThe: 'i',
    femininePluralAndThe: 'e le',
    recipes: 'ricette',
    email: 'email',
    confirmEmail: 'conferma email',
    save: 'Salva',
    country: 'paese di residenza',
    recipe: 'ricetta',
    person: 'persona',
    persons: 'persone',
    people: 'persone',
    buttonSave: 'Salva',
    goBack: 'Indietro',
    today: 'Oggi',
    day: 'Giorno',
    fromTo: 'dal - al',
    fromDate: 'da',
    toDate: 'a',
    of: 'di',
    hourAbbreviation: 'h',
    minuteAbbreviation: 'min',
    hour: 'ora',
    minute: 'minuto',
    month: 'mese',
    months: 'mesi',
    year: 'anno',
    years: 'anni',
    hours: 'ore',
    minutes: 'minuti',
    hourFirstLetterCapitalized: 'Ora',
    time: 'Ora',
    days: 'Giorni',
    monday: 'Lunedì',
    tuesday: 'Martedì',
    wednesday: 'Mercoledì',
    thursday: 'Giovedì',
    friday: 'Venerdì',
    saturday: 'Sabato',
    sunday: 'Domenica',
    dateFormat: 'GG / MM / AAAA',
    all: 'Tutti',
    noDaysSelected: 'Nessuno',
    and: 'e',
    exclamationMark: '!',
    delete: 'Cancella',
    notFoundPage: 'La pagina non esiste o non hai i permessi per vederla.',
    somethingGoesWrong: 'Qualcosa è andato storto.',
    warning: 'Attenzione',
    AppStoreIcon: 'App store',
    PlayStoreIcon: 'Play store',
    understood: 'Ho capito',
    saveChanges: 'Salva modifiche',
    goBackward: 'Torna indietro',
    goForward: 'Prosegui',
    roles: {
        familyMember: 'Membro della famiglia',
        familyLeader: 'Amministratore',
        guests: 'Ospiti',
    },
    toast: {
        titleInvitesToPlan: 'Link copiato.',
        titleDisableMeal: 'Il tuo pasto è stato disattivato.',
        titleDisableRecipe: 'La ricetta è stata disattivata.',
        titleEnableMeal: 'Il tuo pasto è stato riattivato.',
        titleEnableRecipe: 'La ricetta è stata riattivata.',
        titleAddGuestsToMeal: 'Gli ospiti sono stati aggiunti al tuo pasto.',
        titleAddGuestsToRecipe: 'Gli ospiti sono stati aggiunti alla ricetta.',
    },
    modal: {
        descriptionFamilyCheck: 'Il codice inserito è già stato utilizzato. Prosegui con lo stesso codice o crea un nuovo account.',
        buttonConfirmFamilyCheck: 'Continua',
        buttonDeniedFamilyCheck: 'Nuovo account',
    },
    splashscreen: {
        title: 'Il piano alimentare\nvegetale che ti\nsemplifica la vita.',
        buttonTitle: 'Entra',
        slowMessage: 'Caricamento',
        signUp: {
            title: 'Entra in Planter',
            description: 'Crea un account oppure accedi a un account esistente per ottenere il tuo piano alimentare personalizzato, migliaia di ricette vegetali e il supporto del nostro team di nutrizionisti.',
        },
        accessCode: {
            title: 'Codice di accesso',
            description: 'Inserisci il codice che abbiamo inviato a %{email}.',
            noEmailRecived: 'Non hai ricevuto la mail?',
            resendcheckSpamEmail: 'Controlla in spam oppure',
            resendEmail: 'invia di nuovo',
        },
        intro: {
            title: 'Il piano alimentare vegetale che ti semplifica la vita.',
            buttonTitle: 'Entra',
            buttonTrial: 'Inizia la prova gratuita di 7 giorni',
            alreadyHaveAccount: 'Hai già un account?',
        },
        welcome: {
            title: 'Ciao ',
            secondTitle: ', ti diamo il benvenuto in Planter!',
            subTitle: 'Ottieni subito il tuo piano alimentare personalizzato, naviga tra le migliaia di ricette vegetali e approfitta del nostro team di nutrizionisti a tua disposizione.',
            buttonTitle: 'Inizia',
        },
    },
    menu: {
        plan: 'Piano',
        list: 'Lista',
        explore: 'Esplora',
        ask: 'Chiedi',
    },
    searchPlaceholder: 'Cerca una ricetta',
    favorite: 'Preferiti',
    otpValidation: {
        title: 'Verifica mail',
        description: 'Inserisci il codice che abbiamo inviato a \n',
        secondDescription: 'Non hai ricevuto la mail? \nControlla in spam oppure ',
        titleLink: 'Invia di nuovo',
        buttonTitle: 'Continua',
    },
    emailCheckScreen: {
        title: 'Entra in Planter',
        description: 'Crea un account oppure accedi a un account esistente per ottenere il tuo piano alimentare personalizzato, migliaia di ricette vegetali e il supporto del nostro team di nutrizionisti.',
        buttonTitle: 'Continua',
        emailRequiredError: 'Il campo è obbligatorio.',
        emailFormatError: 'Il formato non è corretto.',
    },
    serveSelectionScreen: {
        title: 'Scegli il fuso orario',
        description: 'Planter userà questa informazione per aggiungere nuovi giorni al tuo piano alimentare di notte, mentre dormi.',
        footerDescription: "In base a dove sceglierai il server verranno calcolati i nuovi giorni concordi al tuo fuso orario e ti consentiranno di usare l'app al massimo delle sue prestazioni.",
        europe: 'Europa (CET)',
        northAmerica: 'America (CST)',
        requiredServerError: 'Seleziona un fuso orario per procedere.',
    },
    countryScreen: {
        title: 'Entra in Planter',
        description: 'Crea un account oppure accedi a un account esistente per ottenere il tuo piano alimentare personalizzato, migliaia di ricette vegetali e il supporto del nostro team di nutrizionisti.',
        buttonTitle: 'Continua',
        emailFormatError: 'Il formato non è corretto.',
        labelCountry: 'Paese di residenza',
        termsAndConditions: 'Termini e Condizioni',
        termsAndUseConditions: "Termini e Condizioni d'uso",
        bottomSubTitle: 'Proseguendo accetto le',
        termsOfUse: "Condizioni d'uso",
        bottomSubTitleThe: ', le',
        termsOfSale: 'Condizioni di vendita',
        bottomSubTitleAnd: 'e la',
        privacyPolicy: 'Privacy Policy',
        bottomSubTitlePlanter: ' di Planter.',
        secondBottomSubTitle: 'Voglio ricevere la newsletter con aggiornamenti e contenuti promozionali da parte di Planter.',
        acceptPrivacyPolicy: 'Proseguendo accetto la */ppPrivacy Policy/pp* di Planter.',
        acceptTerms: "Proseguendo accetto i */cuTermini e Condizioni d'uso/cu* e le */cvCondizioni di vendita/cv*.",
        accept: 'Proseguendo accetto',
    },
    placeholder: {
        email: 'e-mail',
        height: 'Altezza',
        growthSpeed: 'Velocità di crescita',
        portion: 'Porzione',
        suggestedPortion: 'Porzione consigliata',
    },
    back: 'Indietro',
    forward: 'Avanti',
    modify: 'Modifica',
    continue: 'Continua',
    done: 'Fatto',
    confirm: 'Conferma',
    cancel: 'Annulla',
    admin: 'Amministratore',
    otherComponents: 'Altri componenti',
    //** SCREEN **//
    walkThrough: {
        counterText: '%{x} di %{y}',
        recipesStepTitle: 'Migliaia di ricette vegetali alla portata di tutti.',
        recipesStepSubtitle: 'Planter ti seguirà nella loro realizzazione passo per passo.',
        shoppingListStepTitle: 'La lista della spesa è sempre aggiornata in tempo reale.',
        shoppingListStepSubtitle: 'Nel mondo, il 17% del cibo che viene acquistato viene buttato. Con Planter, invece, compri solo ciò di cui hai bisogno. E risparmi.',
        mealsPlanStepTitle: 'Menu sempre diversi, sempre bilanciati.',
        mealsPlanStepSubtitle: 'Costruiti intorno alle tue esigenze e ai tuoi gusti.\nE a quelli della tua famiglia.',
        supportChatStepTitle: 'La risposta ad ogni tuo dubbio.',
        supportChatStepSubtitle: 'Avrai il nostro team di nutrizionisti esperti di alimentazione vegetale a tua disposizione 7 giorni su 7 per rispondere alle tue domande.',
        start: 'Iniziamo!',
    },
    selectAddLabel: 'Aggiunti da te',
    selectAddButtonLabel: 'Aggiungi',
    memberRegistry: {
        counterText: '%{x} / %{y}',
        familyCheckTitle: 'Hai ricevuto un codice famiglia?',
        familyCheckSubtitle: 'Se hai ricevuto un codice da un tuo familiare inseriscilo qui sotto, altrimenti seleziona "Non ho un codice".',
        familyCheckInputPlaceholder: 'Codice Famiglia',
        familyCheckNoCode: 'Non ho un codice',
        familyCheckCodeDoesNotExist: 'Il codice inserito non esiste oppure è già stato utilizzato.',
        familyCheckInputLengthError: 'Inserisci il codice',
        inputNameTitle: 'Qual è il tuo nome?',
        inputNamePlaceholder: 'Modifica nome',
        inputNameSubtitle: 'Piacere di conoscerti!',
        inputNameSubtitleFamilyMember: 'Piacere di fare la sua conoscenza!',
        inputNameRequiredError: 'Il nome è obbligatorio.',
        inputNameValidationError: 'Il formato non è corretto.',
        inputNameMaxLengthError: 'Il nome non può avere più di 30 caratteri.',
        inputNameMinLengthError: 'Il nome deve avere almeno 3 caratteri.',
        modifyName: 'Modifica nome',
        addMember: 'Aggiungi componente',
        inputBirthdateTitle: 'Qual è la tua data di nascita?',
        inputBirthdateSubtitle: 'La tua età è un dato che permette a Planter di calcolare al meglio i tuoi fabbisogni nutrizionali.',
        inputBirthdateSubtitleFamilyMember: 'La sua età è un dato che permette a Planter di calcolare al meglio i suoi fabbisogni nutrizionali.',
        inputBirthdateRequiredError: 'La data di nascita è obbligatoria.',
        inputBirthdateInfantAgeError: "È meglio aspettare i 6 mesi per l'introduzione dei cibi solidi.",
        inputBirthdateAdultToMinorAgeError: "L'utente non può essere impostato come minorenne, crea un nuovo profilo per procedere.",
        inputBirthdateAdultAgeError: 'Devi essere maggiorenne per utilizzare il servizio.',
        inputBirthdateInFuture: 'La data di nascita non può essere nel futuro.',
        inputBirthdateNotValid: 'La data di nascita non è valida.',
        modifyBirthdate: 'Modifica data di nascita',
        inputGenderTitle: 'Qual è il tuo sesso?',
        inputGenderSubtitle: 'La composizione corporea, il dispendio energetico e alcuni fabbisogni nutrizionali sono influenzati, tra le altre cose, anche dall’assetto ormonale.',
        inputGenderSubtitleFamilyMember: 'La composizione corporea, il dispendio energetico e alcuni fabbisogni nutrizionali sono influenzati, tra le altre cose, anche dall’assetto ormonale.',
        inputGenderExpandedNavBarTitle: 'Altro',
        inputGenderFirstOptionLabel: 'Maschio',
        inputGenderSecondOptionLabel: 'Femmina',
        inputGenderThirdOptionLabel: 'Altro',
        inputGenderRequiredError: 'Il campo sesso è obbligatorio.',
        inputGenderExpandableFirstOptionLabel: 'Uomo trans',
        inputGenderExpandableSecondOptionLabel: 'Donna trans',
        inputGenderExpandableThirdOptionLabel: 'Preferisco non dirlo',
        inputGenderExpandableThirdOptionLabelFamilyMember: 'Preferisce non dirlo',
        modifyGender: 'Modifica sesso',
        inputHeightTitle: 'Qual è la tua altezza?',
        inputHeightSubtitle: 'Inserisci la tua altezza.\nAiuterai Planter ad avere un’idea delle porzioni più adatte a te.',
        inputHeightSubtitleFamilyMember: 'Inserisci la sua altezza.\nAiuterai Planter ad avere un’idea delle porzioni più adatte.',
        inputHeightRequiredError: "L'altezza è obbligatoria.",
        inputHeightMinError: "L'altezza minima non deve essere inferiore a 50cm.",
        inputHeightMaxError: "L'altezza massima non deve essere superiore a 220cm.",
        modifyHeight: 'Modifica altezza',
        inputPhysicalActivityTitle: 'Quale di queste frasi descrive meglio il tuo livello di attività fisica?',
        inputPhysicalActivitySubtitle: 'L’attività fisica è uno dei principali fattori che determinano il fabbisogno energetico di una persona.',
        inputPhysicalActivityRequiredError: 'Il campo attività fisica è obbligatorio.',
        inputPhysicalActivityFirstOptionLabel: 'Ho una vita sedentaria, mi muovo in auto o con i mezzi e non riesco a praticare attività fisica con regolarità.',
        inputPhysicalActivitySecondOptionLabel: 'Faccio tutti i giorni almeno 10.000 passi (circa 6 km) e/o mi alleno almeno 2 volte alla settimana per un’ora o più.',
        inputPhysicalActivityThirdOptionLabel: 'Oltre a muovermi il più possibile a piedi o in bicicletta, pratico esercizio fisico per almeno un’ora 4 o più volte a settimana.',
        inputPhysicalActivityFourthOptionLabel: 'Mi alleno ad alta intensità tutti i giorni e/o sono atleta di professione.',
        modifyPhysicalActivity: 'Modifica fascia di attività',
        inputAllergiesTitle: 'Hai delle allergie?',
        inputAllergiesSubtitle: 'Planter non ti proporrà ricette che contengono gli ingredienti che non puoi mangiare.',
        inputAllergiesSubtitleFamilyMember: 'Planter non proporrà ricette che contengono gli ingredienti che non può mangiare.',
        inputAllergiesFirstOptionLabel: 'Glutine',
        inputAllergiesSecondOptionLabel: 'Soia',
        inputAllergiesThirdOptionLabel: 'Frutta secca',
        inputInfiniteScrollLabelAllergies: 'Altre allergie',
        modifyAllergies: 'Modifica allergie',
        modifyGrowthSpeed: 'Modifica crescita',
        inputInfiniteScrollLabelAvoidIngredients: 'Modifica ingredienti da evitare',
        inputAvoidIngredientsTitle: 'Ci sono degli ingredienti che vorresti evitare?',
        inputAvoidIngredientsSubtitle: 'Anche in questo caso, Planter escluderà le ricette che li contengono.\nPotrai modificare queste preferenze anche in futuro.',
        inputAvoidIngredientsSubtitleFamilyMember: 'Anche in questo caso, Planter escluderà le ricette che li contengono.\nPotrete entrambi modificare queste preferenze anche in futuro.',
        modifyAvoidIngredients: 'Modifica ingredienti da evitare',
        modifyPortions: 'Modifica porzioni',
        inputPortionTitle: 'Modifica le porzioni',
        inputPortionSubtitle: 'Quanto vorresti mangiare ?',
        portionEatLess: 'Vorrei mangiare di meno',
        portionEatMore: 'Vorrei mangiare di più',
        portionValues: {
            portionEatMuchLess: 'Molto di meno',
            portionEatLess: 'Di meno',
            portionEatBitLess: "Un po' di meno",
            portionEatStandard: 'Standard',
            portionEatBitMore: "Un po' di più",
            portionEatMore: 'Di più',
            portionEatMuchMore: 'Molto di più',
        },
        portionAppetite: 'L’appetito cambia in base a moltissimi fattori, e varia nel corso del tempo. Potrai modificare le porzioni anche in seguito, tutte le volte che vorrai. Grazie a Planter, il tuo piano alimentare sarà sempre e comunque equilibrato.',
        inputPortionDescription: 'In base alle informazioni che hai inserito, Planter suggerisce le porzioni che ritiene più adatte. La fame, però, è un fattore molto soggettivo. Utilizza i tasti qui sotto per aggiustare le porzioni in base al suo appetito.',
        end: 'Fine',
        welcomePage: {
            title: 'Ciao %{name}, ti diamo il benvenuto in Planter!',
            subtitle: 'Ottieni subito il tuo piano alimentare personalizzato, naviga tra le migliaia di ricette vegetali e approfitta del nostro team di nutrizionisti a tua disposizione.',
            startButtonLabel: 'Inizia',
            sidebar: {
                title: 'Componenti e preferenze',
                subtitle: 'Puoi aggiungere al tuo piano fino a 5 componenti della tua famiglia, oppure saltare questo passaggio e farlo in seguito dalla sezione Profilo.',
                maximumNumberOfMembers: 'Hai raggiunto il numero massimo di componenti da associare al tuo piano.',
                administrator: 'Amministratore',
                otherComponents: 'Altri componenti',
                addComponentButtonLabel: 'Aggiungi',
                SkipButtonLabel: 'Salta',
                CreatePlanButtonLabel: 'Vai al tuo piano',
            },
        },
        //Add Family member
        inputFamilyNameTitle: 'Qual è il suo nome?',
        inputFamilyBirthdateTitle: 'Qual è la data di nascita di %{name}?',
        inputFamilyGenderTitle: 'Qual è il sesso di %{name}?',
        inputFamilyGrowthSpeedTitleFamilyMember: 'Qual è la velocità di crescita di %{name}?',
        inputFamilyGrowthSpeedTitle: 'Qual è la tua velocità di crescita?',
        inputFamilyGrowthSpeedSubtitle: 'Planter utilizzerà questo dato per calcolare al meglio i suoi fabbisogni.',
        inputFamilyGrowthSpeedFirstOptionLabel: 'Sotto la media (< 25° percentile)',
        inputFamilyGrowthSpeedSecondOptionLabel: 'Nella media (25° - 75° percentile)',
        inputFamilyGrowthSpeedThirdOptionLabel: 'Sopra la media (> 75° percentile)',
        inputFamilyGrowthSpeedDescription: 'Planter utilizzerà questo dato per calcolare al meglio i suoi fabbisogni.',
        inputFamilyHeightTitle: "Qual è l'altezza di %{name}?",
        inputFamilyPhysicalActivityTitle: 'Quale di queste frasi descrive meglio il suo livello di attività fisica?',
        inputFamilyPhysicalActivitySubtitle: 'L’attività fisica è uno dei principali fattori che determinano il fabbisogno energetico di una persona.',
        inputFamilyPhysicalActivityFirstOptionLabel: 'Ha una vita sedentaria, si muove in auto o con i mezzi e non riesce a praticare attività fisica con regolarità.',
        inputFamilyPhysicalActivitySecondOptionLabel: 'Fa tutti i giorni almeno 10.000 passi (circa 6 km) e/o si allena almeno 2 volte alla settimana per un’ora o più.',
        inputFamilyPhysicalActivityThirdOptionLabel: 'Oltre a muoversi il più possibile a piedi o in bicicletta, pratica esercizio fisico per almeno un’ora 4 o più volte a settimana.',
        inputFamilyPhysicalActivityFourthOptionLabel: 'Si allena ad alta intensità tutti i giorni e/o è atleta di professione.',
        inputFamilyAllergiesTitle: '%{name} ha delle allergie?',
        inputFamilyAvoidIngredientsTitle: 'Ci sono degli ingredienti che %{name} vorrebbe evitare?',
        parentalResponsibilityCheckboxFirstPart: 'Dichiaro di avere la potestà genitoriale/tutela sul minore e autorizzo il trattamento dei suoi dati personali per i fini del servizio, secondo quanto previsto dalle',
        parentalResponsibilityCheckboxSecondPartCGU: "Condizioni d'uso",
        parentalResponsibilityCheckboxSecondPartCGV: 'Condizioni di vendita',
        parentalResponsibilityCheckboxThirdPart: 'e dalla',
        parentalResponsibilityCheckboxFourthPart: 'Privacy Policy.',
        parentalResponsibilityCheckboxComplete: "Dichiaro di avere la potestà genitoriale/tutela sul minore e autorizzo il trattamento dei suoi dati personali per i fini del servizio, secondo quanto previsto dalle */cuCondizioni d'uso/cu*, */cvCondizioni di vendita/cv* e dalla */ppPrivacy Policy/pp*.",
        editAnswerAlert: 'Attenzione',
        variationPlanAlert: 'Modificando gli allergeni e/o gli ingredienti che uno o più membri della famiglia preferiscono evitare, il piano alimentare verrà riformulato da zero.',
    },
    addComponents: {
        title: 'Componenti e preferenze',
        description: 'Puoi aggiungere al tuo piano fino a 5 componenti della tua famiglia, oppure saltare questo passaggio e farlo in seguito dalla sezione Profilo.',
        buttonTitle: 'Vai al tuo piano',
        fullFamily: 'Hai raggiunto il numero massimo di componenti da associare al tuo piano.',
        familyMember: 'Componente',
        familyMembers: 'Componenti',
        membersAddedToPlan: 'aggiunti al piano',
        editName: 'Modifica nome',
        editAge: 'Modifica età',
        editAvoidIngredients: 'Modifica ingredienti',
        editAllergies: 'Modifica allergie',
        editGender: 'Modifica sesso',
        editActivity: 'Modifica attività fisica',
        editGrowthSpeed: 'Modifica velocità di crescita',
        editHeight: 'Modifica altezza',
    },
    infiniteScrollList: {
        reset: 'Azzera',
        counterTitle: '%{x} di %{y} ingredienti',
        counterTitleNoLimit: '%{x} ingredienti',
        counterSubtitle: 'selezionati',
        selectedItemsSectionTitle: 'Selezionati',
        searchbarPlaceholder: 'Cerca',
    },
    explorePage: {
        weeklyRecipe: 'La ricetta della settimana',
        seeRecipe: 'Vedi ricetta',
        seeAll: 'Vedi tutti',
        seasonalRecipes: 'Ricette di stagione.',
        featuredContents: 'In primo piano.',
        allNewsForYou: 'Tutte le novità per te.',
        respectingNature: 'Fai il pieno di nutrienti.',
        sundayWithTheFamily: 'Weekend ai fornelli.',
        sharingMeals: 'Lasciati ispirare da Planter.',
        youMightLike: 'Potrebbero piacerti',
        placeholderSearch: 'Cerca una ricetta',
        collection: 'Raccolta',
        featuredContentTitle: 'In primo piano.',
        featuredContentSubtitle: 'Tutte le novità per te.',
        filterRecipe: 'ricetta',
        filterRecipes: 'ricette',
        searchNotFoundIconDescription: 'Icona nessun risultato trovato',
        searchNotFound: 'Nessun risultato trovato',
        searchNotFoundDescription: 'Ops, la tua ricerca non ha prodotto alcun risultato.',
    },
    planPage: {
        waitingForPlan: {
            title: 'Planter sta creando il piano alimentare perfetto per te.',
            subtitle: 'Potrebbe volerci qualche minuto.',
        },
        day: 'Giorno',
        week: 'Settimana',
        sectionTitles: {
            BREAKFAST: 'Colazione.',
            MORNING_SNACK: 'Spuntino.',
            LUNCH: 'Pranzo.',
            SNACK: 'Merenda.',
            AFTERNOON_SNACK: 'Merenda.',
            DINNER: 'Cena.',
            ADDED: 'Aggiunte da te.',
        },
        sectionSubTitles: {
            BREAKFAST: 'Inizia bene la giornata.',
            MORNING_SNACK: 'Ricarica le batterie.',
            LUNCH: 'Fai il pieno di energia.',
            SNACK: 'Prenditi una pausa.',
            AFTERNOON_SNACK: 'Prenditi una pausa.',
            DINNER: 'Chiudi in bellezza.',
            ADDED: 'Le tue ricette fuori dal piano.',
        },
        incompletePlanMessage: 'Piano incompleto per questo giorno.',
        modifyRecipe: 'Modifica ricetta',
        modalModifyRecipeQuestion: 'Quale modifica vuoi fare alla ricetta %{recipeName}?',
        changeRecipe: 'Sostituire la ricetta',
        replace: 'Sostituisci',
        changeHeaderRecipe: 'Sostituisci ricetta',
        changeRecipeDescription: 'Componenti assegnati a %{recipeName}?',
        createMealDescription: 'Componenti assegnati a %{meal}',
        createMeal: 'Crea il tuo pasto',
        disableRecipe: 'Disattivare la ricetta',
        whoToDisableOnRecipe: 'Per chi vuoi disattivare la ricetta %{recipeName}?',
        whoToDisableOnRecipeDescription: 'Scegli se vuoi disabilitare la ricetta per tutta la famiglia o per uno o più componenti.',
        whoToEnableOnRecipe: 'Per chi vuoi riattivare la ricetta %{recipeName}?',
        whoToEnableOnRecipeDescription: 'Scegli se vuoi riattivare la ricetta per tutta la famiglia o per uno o più componenti.',
        modifyMeal: 'Modifica pasto',
        modalModifyMealQuestion: 'Quale modifica vuoi fare al pasto %{meal}?',
        modalModifyMealQuestionFirstPart: 'Quali modifiche vuoi fare',
        modalModifyMealQuestionBreakfast: 'alla colazione',
        modalModifyMealQuestionLunch: 'al pranzo',
        modalModifyMealQuestionMorningSnack: 'allo spuntino',
        modalModifyMealQuestionSnack: 'alla merenda',
        modalModifyMealQuestionDinner: 'alla cena',
        modalModifyMealQuestionAdded: 'alle ricette aggiunte da te',
        modalModifyMealQuestionSecondPart: '?',
        createSostitutiveMeal: 'Creare un pasto sostitutivo',
        disableMeal: 'Disattivare il pasto',
        whoToDisableOnMealFirstPart: 'Per chi vuoi disattivare',
        whoToDisableOnMealSecondPart: '?',
        whoToDisableOnMealDescription: 'Scegli se vuoi disabilitare il pasto per tutta la famiglia o per uno o più componenti.',
        whoToEnableOnMealFirstPart: 'Per chi vuoi riattivare',
        articleMeals: {
            BREAKFAST: 'la colazione',
            MORNING_SNACK: 'lo spuntino',
            LUNCH: 'il pranzo',
            SNACK: 'la merenda',
            AFTERNOON_SNACK: 'la merenda',
            DINNER: 'la cena',
            ADDED: 'delle ricette aggiunte da te',
        },
        whoToEnableOnMealSecondPart: '?',
        whoToEnableOnMealDescription: 'Scegli se vuoi riattivare il pasto per tutta la famiglia o per uno o più componenti.',
        whoToSubstituteRecipe: 'Per chi vuoi sostituire la ricetta %{recipeName}?',
        howManyGuestsForMeal: 'Quanti ospiti vuoi aggiungere al pasto %{meal}?',
        howManyGuestsForRecipe: 'Quanti ospiti vuoi aggiungere alla ricetta %{recipeName}?',
        howManyGuestsAreThereForMeal: 'Quanti ospiti sono presenti al pasto %{meal}?',
        howManyGuestsAreThereForRecipe: 'Quanti ospiti sono presenti alla ricetta %{recipeName}?',
        forWhomToCreate: 'Per chi vuoi creare il pasto sostitutivo?',
        recipeDisabledSuccessfully: 'La ricetta è stata disattivata.',
        mealDisabledSuccessfully: 'Il tuo pasto è stato disattivato.',
        recipeSubstitutedSuccessfully: 'La ricetta è stata sostituita.',
        disableEntity: 'disattiva %{entity}',
        allComponents: 'Tutti i componenti',
        selectBalancedMealTitle: 'Scegli uno di questi pasti bilanciati o creane uno nuovo',
        choose: 'Scegli',
        createYourMeal: 'Crea il tuo pasto',
        nutrientsGroup: 'Gruppi alimentari',
        yourMeal: 'Il tuo pasto',
        selectAMeal: 'Aggiungi un piatto',
        select: 'Seleziona',
        balancedRecipesFound: '%{noun} equilibrate per questo pasto',
        todayTips: 'I consigli di oggi',
        forYourPlanning: 'Per il tuo planning',
        addGuests: 'Aggiungere ospiti',
        manageGuests: 'Gestire gli ospiti',
        guestsManagement: 'Gestisci ospiti',
        staticAdvice: {
            b12VitaminTitle: 'Vitamina B12',
            b12VitaminDescription: 'Hai preso la vitamina B12 oggi?',
            omega3Title: 'Omega-3',
            omega3Description: 'Non dimenticarti degli omega-3! Con due cucchiai di semi di lino o di chia macinati, oppure con un cucchiaio di olio di semi di lino si raggiunge il fabbisogno a tutte le età.',
        },
        loadingAlternativeMeal: {
            title: 'Planter sta cercando delle alternative bilanciate.',
            description: 'Attendi qualche istante.',
        },
        remove: 'Rimuovi',
        enableRecipe: 'Riattivare la ricetta',
        enableMeal: 'Riattivare il pasto',
    },
    listPage: {
        waitingForPlan: {
            title: 'La lista della spesa sarà pronta insieme al piano alimentare.',
            subtitle: 'Potrebbe volerci qualche minuto.',
        },
        title: 'Lista della spesa',
        ingredients: 'Ingredienti',
        toBuy: 'da acquistare',
        subtitleFirstPart: 'Probabilmente avrai già molti di questi ingredienti.',
        subtitleSecondPart: 'Meglio controllare prima di fare la spesa!',
    },
    subscription: {
        firstPage: {
            title: 'Cosa ti offre Planter:',
            btnLabel: 'Inizia la prova gratuita',
            features: {
                first: {
                    title: '7 giorni di prova',
                    description: 'Dopo i primi 7 giorni di prova gratuita si attiverà un abbonamento che potrai disdire quando vuoi.',
                },
                second: {
                    title: 'Migliaia di ricette vegetali',
                    description: 'Spiegate passo passo e alla portata di tutti.',
                },
                third: {
                    title: 'Menu settimanali personalizzati',
                    description: 'Un piano bilanciato creato su misura per te e per la tua famiglia.',
                },
                fourth: {
                    title: 'Nutrizionista on demand 7/7',
                    description: 'Risolvi i tuoi dubbi con il nostro team di esperti ogni volta che ne avrai bisogno.',
                },
                fifth: {
                    title: 'Attenzione per i più piccoli',
                    description: 'Indicazioni nutrizionali dettagliate per i bambini sin dallo svezzamento.',
                },
                sixth: {
                    title: 'Lista della spesa',
                    description: 'Acquisterai solo il necessario, e avrai sempre tutti gli ingredienti che ti servono.',
                },
            },
        },
        secondPage: {
            title: 'Entra in Planter',
            subtitleFirstPart: 'Planter è un servizio a pagamento. Nel prezzo è compreso un piano personalizzato sulla base delle necessità tue e della tua famiglia. Potrai aggiungere i dati e le esigenze dei tuoi familiari in seguito, fino ad un massimo di',
            subtitleSecondPart: '5 persone',
            saleCodeFirstPart: 'Hai un',
            saleCodeSecondPart: 'codice sconto',
            freeTrial: 'Inizia la prova gratuita',
            footer: {
                descriptionFirstPart: 'Al termine della prova gratuita di 7 giorni l’abbonamento sarà attivo e si rinnoverà automaticamente. Potrai cancellarlo in ogni momento. Se non vuoi procedere, puoi cancellare i dati finora inseriti',
                descriptionSecondPartMobile: 'premendo qui',
                descriptionSecondPartWeb: 'cliccando qui',
                restorePurchase: 'Ripristina un acquisto',
                termsAndConditions: 'Termini e condizioni',
            },
            mostConvenientLabel: 'Il più conveniente',
        },
        paymentMade: 'Il pagamento avvenuto con successo',
    },
    profilePage: {
        profileManagement: 'Gestione profilo',
        subscriptionDetails: 'Dettagli fatturazione',
        payment: 'Pagamenti',
        notifications: 'Notifiche',
        reminder: 'Promemoria',
        b12Vitamin: 'Vitamina B12',
        omega3: 'Omega-3',
        notificationsAndReminder: 'Promemoria e notifiche',
        notificationsAndReminderTitle: 'Promemoria e\nnotifiche',
        b12VitaminNotificationDescription: 'Ricordati di assumere il tuo\nintegratore di vitamina B12.',
        omega3NotificationDescription: 'Nel corso della giornata, aggiungi al piatto che preferisci un cucchiaio di olio di semi di lino oppure due cucchiai di semi di lino o di chia macinati.',
        dontRemindMeAgain: 'Non ricordarmelo più',
        planInfo: 'Informazioni sul piano',
        languageAndUnitOfMeasure: 'Lingua e unità di misura',
        familyMembers: 'Componenti e preferenze',
        inviteFriend: 'Invita un amico',
        help: 'Ricevi assistenza',
        termsOfService: 'Termini e condizioni',
        termsOfUse: "Condizioni d'uso",
        termsOfSale: 'Condizioni di vendita',
        privacyPolicy: 'Informativa sulla privacy',
        cookie: 'Cookie',
        logout: 'Esci',
        logoutModal: {
            title: 'Logout',
            description: 'Sei sicuro di voler uscire dal tuo account?',
        },
        deleteAccount: 'Elimina account',
        deleteAccountModal: {
            title: 'Elimina account',
            description: 'Sei sicuro di voler procedere con l’eliminazione del tuo account?',
        },
        deleteAccountSidebar: {
            title: '%{name},\nvuoi eliminare il tuo account?',
            description: 'Il tuo account e tutti i dati ad esso associati verranno eliminati immediatamente.\n' +
                'Se hai un abbonamento a Planter attivo non potrai più accedervi, anche se il periodo per il quale hai già pagato non è ancora terminato.',
            deleteAccountconsequences: {
                title: 'Se elimini il tuo account:',
                firstConsequence: 'Non potrai più accedere al piano alimentare, alle ricette e alla chat di Planter con questo account.',
                secondConsequence: 'Le informazioni memorizzate all’interno del tuo account, come le preferenze alimentari tue e dei tuoi familiari e la cronologia della tua chat con i nutrizionisti verranno eliminate definitivamente.',
                thirdConsequence: 'Se hai acquistato Planter ad un prezzo speciale, è possibile che tu non possa in futuro accedere alle stesse condizioni.',
            },
        },
        subscriptionDetailsPage: {
            title: 'Dettagli fatturazione',
            subtitle: 'Le modalità con le quali puoi visualizzare i tuoi dettagli di fatturazione, modificare la frequenza di fatturazione o annullare il tuo abbonamento a Planter cambiano a seconda di come hai effettuato l’acquisto.',
            list: {
                first: {
                    firstPart: 'Se hai acquistato Planter sul sito',
                    secondPart: 'planter.eco',
                    thirdPart: 'e hai pagato tramite Stripe, le modalità di gestione del tuo abbonamento sono descritte nel dettaglio',
                    fourthPart: 'a questo link',
                    fifthPart: 'Se incontri delle difficoltà o hai bisogno di aiuto, scrivici a',
                    sixthPart: 'support@planter.eco',
                },
                second: {
                    firstPart: 'Se hai acquistato Planter su',
                    secondPart: 'App Store',
                    thirdPart: ', puoi gestire il tuo abbonamento dalle Impostazioni → Il tuo nome → Abbonamenti.',
                },
                third: {
                    firstPart: 'Se hai acquistato Planter su',
                    secondPart: 'Google Play',
                    thirdPart: ', puoi gestire il tuo abbonamento dal Play Store → Il tuo account → Abbonamenti.',
                },
            },
            attention: {
                attention: 'Attenzione',
                explanation: {
                    firstPart: 'se hai acquistato Planter sul sito planter.eco e inserito i dati della tua carta su Stripe, è corretto non visualizzare l’abbonamento tra quelli acquistati tramite App Store o Google Play. In questo caso puoi gestire il tuo abbonamento dalla web-app su computer, oppure dal tuo smartphone cliccando direttamente sul link che hai ricevuto da Stripe al momento dell’iscrizione e che trovi anche nelle ',
                    secondPart: 'nostre FAQ',
                    thirdPart: '.',
                },
            },
            viewStripePortal: 'Gestisci il tuo abbonamento su Stripe',
        },
        sections: {
            modifyEmail: 'Modifica email',
            modifyCountry: 'Modifica country',
            updateEmailTitle: 'Aggiorna indirizzo email',
            updateEmailSubtitle: 'Inserisci un nuovo indirizzo e-mail.',
        },
        notificationHistory: {
            title: 'Notifiche',
            seeRecipe: 'Vedi ricetta',
            notFound: 'Non ci sono notifiche.',
        },
        familyMembersSection: {
            title: 'Il tuo profilo',
            componentsAndPreferences: 'Componenti e preferenze',
            description: 'Puoi aggiungere o rimuovere componenti al piano alimentare, oltre ad aggiornare i dati personali e le preferenze di ciascuno.',
            addMemberNotificationFemale: '%{name} è stata aggiunta al piano.',
            addMemberNotificationMale: '%{name} è stato aggiunto al piano.',
            addMemberNotificationUnknownGender: '%{name} è statə aggiuntə al piano.',
            infoModal: {
                button: 'Info',
                title: 'Informazione Importante!',
                description: 'Attenzione che modificando allergie e ingredienti da evitare il piano attuale verrà cancellato e verrà riformulato.',
            },
            allergiesDislikedIngredientsModal: {
                title: 'Attenzione',
                description: 'Modificando gli allergeni e/o gli ingredienti che uno o più membri della famiglia preferiscono evitare, il piano alimentare verrà riformulato da zero.',
            },
            changePortionModal: {
                title: 'Modifica porzioni',
                description: 'Cambiando le preferenze, il piano alimentare verrà aggiornato.\n Vuoi confermare la modifica?',
            },
        },
        PlanInformationPage: {
            title: 'Informazioni sul piano',
            firstParagraph: {
                firstPart: 'Il piano alimentare creato da Planter viene',
                secondPart: 'elaborato sulla base delle più aggiornate linee guida peer-reviewed',
                thirdPart: 'in merito all’alimentazione vegetale.',
            },
            secondParagraph: 'La dr.ssa Silvia Goggi, medico chirurgo specialista in Scienza dell’Alimentazione, ha creato l’algoritmo alla base del funzionamento di Planter coniugando tali linee guida con la sua esperienza clinica.',
            thirdParagraph: 'Tale algoritmo, infatti, è in grado di selezionare le ricette, aggiustare le dosi dei singoli ingredienti e creare un piano alimentare bilanciato.\nPuoi consultare gli articoli delle linee guida originali ai seguenti link:',
            firstBookTitle: 'VegPlate: A Mediterranean-Based Food Guide for Italian Adult, Pregnant, and Lactating Vegetarians.',
            secondBookTitle: 'Planning Well-Balanced Vegetarian Diets in Infants, Children, and Adolescents: The VegPlate Junior.',
            thirdBookTitle: 'Vegan Nutrition for Mothers and Children: Practical Tools for Healthcare Providers.',
            lastParagraph: 'Planter non sostituisce il parere del tuo medico curante, che ti consigliamo di contattare per qualsiasi problematica o dubbio relativi al tuo stato di salute.',
        },
        languageAndUnitOfMeasurePage: {
            title: 'Lingua e unità di misura',
            subtitle: 'Scegli in che lingua vuoi utilizzare Planter e in quali unità di misura vuoi che siano espressi gli ingredienti.',
            language: 'Lingua',
            languages: {
                italian: 'Italiano',
                englishUK: 'Inglese',
                englishUS: 'Inglese (USA)',
            },
            unitOfMeasure: 'Unità di misura',
            unitOfMeasures: {
                metric: 'Sistema internazionale (grammi e litri)',
                imperial: 'USA (once e tazze)',
            },
            saveButton: 'Salva',
            savedChanges: 'Le impostazioni sono state salvate correttamente',
            errorInSavingChanges: 'Non è stato possibile salvare correttamente le impostazioni',
        },
    },
    invitesToThePlan: {
        navBarTitle: 'Invita al piano',
        title: 'Invita %{name} in Planter',
        description: 'Con questo codice, %{name} potrà accedere gratuitamente a Planter e prendere visione del piano alimentare familiare, della lista della spesa e di tutte le ricette.',
        labelCopyLink: 'Copia codice',
        labelShare: 'Condividi esternamente',
    },
    searchResultPage: {
        subtitle: 'Risultati per',
    },
    favouritesPage: {
        favourites: 'Ricette preferite',
        noResult: 'Nessuna ricetta salvata nei preferiti.',
    },
    memberProfile: {
        addMemberButton: 'Invita al piano',
        deletedMemberButton: 'Rimuovi componente dal piano',
        deletedMemberModal: {
            title: 'Rimuovi componente',
            description: 'Stai rimuovendo un componente dal piano condiviso. Vuoi procedere con la modifica?',
            notification: '%{name} è stato rimosso dal piano',
        },
        emptyItem: 'Nessuno',
        emptyAllergies: 'Nessuna',
        name: 'Nome',
        titleBirthDate: 'Modifica data di nascita',
        titleGender: 'Modifica sesso',
        height: 'Modifica altezza',
        titleActivity: 'Modifica attività fisica',
        titleAllergies: 'Modifica allergie',
        excludedIngredients: 'Modifica ingredienti da evitare',
        growthSpeed: 'Modifica velocità di crescita',
        portion: 'Modifica porzione',
        profileScreenTitle: 'Modifica informazioni',
    },
    notificationAlert: {
        title: 'Errore',
        codeError: 'Codice errato.',
        mailVerified: 'Mail verificata.',
        mailResent: 'Abbiamo inviato una nuova mail.',
        genericError: 'Si è verificato un errore, riprova.',
        copyToClipboard: 'Copiato nella Clipboard.',
    },
    bottomTab: {
        plan: 'Piano',
        list: 'Lista',
        explore: 'Esplora',
        ask: 'Chiedi',
        profile: 'Profilo',
    },
    recipeDetail: {
        showDosesMembers: 'Vedi dosi per componente',
        notifyIngredientsDescription: 'Questa ricetta contiene ingredienti non compatibili con le preferenze impostate da te e/o dai tuoi familiari.',
        ingredients: 'Ingredienti',
        preparation: 'Preparazione',
        notes: 'Note',
        cereals: 'Cereali',
        proteicFood: 'Cibi proteici',
        fruits: 'Frutta',
        vegetables: 'Verdura',
        vegetableFats: 'Grassi vegetali',
        foodGroups: 'Gruppi alimentari',
        weaningAlertTitle: 'Per i bambini in svezzamento',
        tipTitle: 'Il consiglio di Planter',
        nutrientsGroup: 'Gruppi alimentari',
        recipeFor: 'Ricetta per',
        personNumber: 'N° persone',
        minutes: 'min',
        optional: 'Facoltativo',
        people: 'Persone',
        date: 'Data',
        meal: 'Pasto',
        addToPlan: 'Aggiungi al piano',
        selectMembersTitle: 'Per chi vuoi aggiungere la ricetta?',
        guests: 'Ospiti',
        quest: 'Ospite',
        planDateSelectTitle: 'Quando vorresti preparare la ricetta?',
        componentsValue: '%{count} componenti',
        addedToPlan: 'aggiunti al piano',
        hasBeenAddedToPlan: 'La ricetta è stata aggiunta al piano.',
        planInformationSectionTitle: 'In piano per',
        shareError: 'Impossibile condividere la ricetta al momento',
        shareSucceeded: 'Ricetta condivisa correttamente.',
        shareMessage: "Dai un'occhiata a questa ricetta di Planter!\n%{url}",
        shareLinkTitle: "Dai un'occhiata a questa ricetta su Planter!",
    },
    plan: {
        pendingTitle: 'Planter sta creando il piano alimentare perfetto per te.',
        pendingDescription: 'Potrebbe volerci qualche minuto.',
        editMeal: 'Modifica pasto',
        whichSnackToEdit: 'Quale modifica alla merenda vuoi fare?',
        forWhomToAddRecipe: 'Per chi vuoi aggiungere la ricetta?',
        guests: 'Ospiti',
        guest: 'Ospite',
        forWhomToDisable: 'Per chi vuoi disattivare la merenda?',
        forWhomToDisableMealDescription: 'Scegli se vuoi disabilitare il pasto per un componente o per tutta la famiglia.',
        forWhomToDisableRecipeDescription: 'Scegli se vuoi disabilitare la ricetta per un componente o per tutta la famiglia.',
        forWhomToCreate: 'Per chi vuoi creare il pranzo sostitutivo?',
        disableMealHeader: 'Disattiva pasto',
        disableMealFooterDescription: 'disabilita pasto',
        disableRecipeFooterDescription: 'disattiva ricetta',
        enableMealFooterDescription: 'riabilita pasto',
        enableRecipeFooterDescription: 'riattiva ricetta',
        mealReplacement: 'pasto sostitutivo',
        recipeReplacement: 'ricetta sostitutiva',
        manageMealGuestsFooterDescription: 'aggiunti al pasto',
        manageRecipeGuestsFooterDescription: 'aggiunti alla ricetta',
    },
    askPage: {
        solveYourDoubts: 'Risolvi i tuoi dubbi',
        chatWithUsTitle: 'Vuoi contattare un nutrizionista?',
        chatWithUsBtn: 'Scrivi al nostro team',
        frequentQuestionsTitle: 'Domande frequenti',
        frequentQuestionsFirstDescription: 'Hai fretta di trovare una risposta?',
        frequentQuestionsSecondDescription: 'Qui trovi risposta alle domande più frequenti',
        frequentQuestionsMobileDescription: 'Consulta le nostre FAQ',
        faqPageTitle: 'FAQ',
    },
    maintenancePage: {
        title: 'Stiamo facendo delle operazioni\ndi manutenzione per rendere\nPlanter ancora più performante.',
        titleMobile: 'Stiamo facendo delle operazioni di manutenzione per rendere Planter ancora più performante.',
        subtitle: 'Il servizio ritornerà disponibile a breve.',
        altImage: 'Dishes',
    },
    profile: {
        title: 'Ciao,',
        exit: 'Esci',
        version: 'VERSIONE %{version} (%{buildNumber})',
        titleSectionFavourite: 'Le tue ricette preferite.',
        subTitleSectionFavourite: 'Quello che più ti è piaciuto.',
        account: {
            title: 'Account',
            profileManagerScreen: {
                buttonTitle: 'Elimina account',
            },
            changeCountry: {
                titleHeader: 'Modifica paese di residenza',
            },
            changeEmail: {
                titleHeader: 'Aggiorna indirizzo email',
                warningText: 'Dopo aver aggiornato il tuo indirizzo e-mail dovrai effettuare nuovamente il login con il nuovo indirizzo e-mail per poter accedere a Planter.',
                subTitle: 'Inserisci un nuovo indirizzo email.',
                email: 'email',
                confirmEmail: 'Conferma email',
                modal: {
                    title: 'Aggiorna indirizzo email',
                    description: "Sei sicuro di voler cambiare l'indirizzo email? Verrà effettuato il logout.",
                },
            },
        },
        plan: {
            title: 'Piano alimentare',
            components: 'Componenti e preferenze',
            recurrentAbsenceTitle: 'Gestione assenze ricorrenti',
            recurrentAbsenceDescription: 'Seleziona uno o più pasti nell’arco della settimana che tu e/o i tuoi familiari siete soliti non consumare a casa.',
            editAbsences: 'Modifica assenze',
            confirmDiscardChanges: 'Vuoi uscire senza salvare le\nmodifiche?',
            allComponents: 'Tutti i componenti',
            recurrences: {
                meals: {
                    BREAKFAST: 'Colazione',
                    MORNING_SNACK: 'Spuntino',
                    LUNCH: 'Pranzo',
                    SNACK: 'Merenda',
                    DINNER: 'Cena',
                    ALL_MEALS: 'Tutti i pasti',
                },
                days: {
                    0: 'Domenica',
                    1: 'Lunedì',
                    2: 'Martedì',
                    3: 'Mercoledì',
                    4: 'Giovedì',
                    5: 'Venerdì',
                    6: 'Sabato',
                },
                noAbsence: 'Nessuna assenza',
                everyDay: 'Tutti i giorni',
                onlyLeaderCanManage: "Solo l'amministratore pu\u00F2 eseguire questa operazione",
            },
        },
        invitations: {
            title: 'Inviti e crediti',
            inviteFriend: 'Invita un amico',
        },
        support: {
            title: 'Assistenza',
            getSupport: 'Ricevi assistenza',
        },
        legal: {
            title: 'Informazioni',
            termsService: 'Termini e condizioni',
            privacy: 'Informativa sulla privacy',
        },
        deleteAccount: {
            title: 'Cancella account',
            description: "Sei sicuro di voler cancellare il tuo account? L'operazione non è reversibile.",
        },
    },
    filters: {
        all: 'Tutti',
        otherElements: 'altri',
        other: '+ altro',
        element: '+ altri',
        indifferent: 'Indifferente',
        filters: 'Filtri',
        reset: 'Azzera',
        showRecipes: 'Visualizza ricette',
        resultsFor: 'Risultati per: ',
        noResults: 'Ops, la tua ricerca non ha prodotto alcun risultato!',
        general: {
            ingredients: 'Ingredienti',
            categories: 'Categorie',
            fromWorld: 'Dal mondo',
            seasonalRecipes: 'Ricette di stagione',
            preparationTime: 'Tempo di preparazione',
            nutrients: 'Gruppi alimentari',
            preferred: 'Preferiti',
        },
        preparationTime: {
            short: 'Breve',
            medium: 'Intermedio',
            long: 'Lungo',
            shortTime: '(< 20 minuti)',
            mediumTime: '(20-60 minuti)',
            longTime: '(60+ minuti)',
        },
        chips: {
            preparationTime: {
                short: 'T.p. Breve',
                medium: 'T.p. Intermedio',
                long: 'T.p. Lungo',
            },
            seasonalRecipes: 'Solo ricette di stagione',
            notSeasonalRecipes: 'Ricette fuori stagione',
        },
    },
    tooSmallScreen: {
        title: "Il tuo schermo è troppo piccolo per poter visualizzare il contenuto. Scarica l'app!",
    },
    datePicker: {
        headerTitle: 'Seleziona uno o più giorni',
        selectOneDate: 'Seleziona una data',
    },
    alerts: {
        errorTitle: 'Errore',
        errorMesage: 'Impossibile aprire la pagina web in questo momento, riprovare più tardi',
        permissionRefused: 'In passato hai rifiutato di concedere i permessi, vai alle impostazioni per cambiare la tua scelta.',
        goToSettings: 'Vai alle impostazioni',
        featureUnavailable: 'Funzione non disponibile.',
        featureUnavailableMessage: 'Questa funzione non è disponibile sul tuo smartphone.',
        close: 'Chiudi',
    },
    editUserPreferences: {
        portions: 'Porzioni',
        title: 'Modifica le porzioni',
        familyLeaderEditPreferencesTitle: 'Quanto vorresti mangiare ?',
        familyMemberEditPreferencesTitle: 'Quanto vorrebbe mangiare ?',
        familyLeaderMainDescription: 'In base alle informazioni che hai inserito, Planter ti suggerisce le porzioni che ritiene più adatte a te. La tua fame, però, la conosci meglio tu di chiunque altro. Utilizza i tasti qui sotto per aggiustare le porzioni in base al tuo appetito.',
        familyMemberMainDescription: 'In base alle informazioni che hai inserito, Planter suggerisce le porzioni che ritiene più adatte. La fame, però, è un fattore molto soggettivo. Utilizza i tasti qui sotto per aggiustare le porzioni in base al suo appetito.',
        userPreferences: {
            eatMore: 'Vorrebbe mangiare\ndi più',
            eatLess: 'Vorrebbe mangiare\ndi meno',
            descriptionTxt: 'L’appetito cambia in base a moltissimi fattori, e varia nel corso del tempo. Potrai modificare le porzioni anche in seguito, tutte le volte che vorrai. Grazie a Planter, il tuo piano alimentare sarà sempre e comunque equilibrato.',
        },
        editUserPreferencesModal: {
            title: 'Modifica piano alimentare',
            description: 'Cambiando le preferenze, il piano alimentare verrà aggiornato.\n Vuoi confermare la modifica?',
        },
    },
    list: {
        titleHeader: 'Lista della spesa',
        titleLoader: 'La lista della spesa sarà pronta insieme al piano alimentare.',
        descriptionLoader: 'Potrebbe volerci qualche minuto.',
        subTitle: 'Ingredienti',
        description: 'da acquistare',
        subtitle: 'Probabilmente avrai già molti di questi ingredienti.\nMeglio controllare prima di fare la spesa!',
        selectedIngredientsDescription: 'acquistati',
        foodStorageTitle: 'Liste della spesa',
        foodStorageDescription: 'Crea una o più liste della spesa.\n' +
            'Verranno condivise tra tutti i componenti della famiglia e saranno sempre aggiornate in base al vostro piano.',
        showList: 'Vedi lista',
        atLeastOneFoodStorageError: 'Devi avere almeno una lista attiva.',
    },
    uploadImage: {
        takeAPhoto: 'Scatta una foto',
        chooseAPhoto: 'Scegli foto',
        chooseFromLibrary: 'Scegli dalla libreria',
        undo: 'Annulla',
    },
    notificationsHistory: {
        title: 'Notifiche',
    },
    errorCode: {
        emailAreadyExist: "Esiste già un account con l'indirizzo email indicato.",
        codeNotFound: 'Codice non trovato.',
        codeNotExist: 'Il codice fornito non esiste.',
        codeAlreadyUsed: 'Codice già utilizzato.',
        userNotFound: 'Utente non trovato o non esistente.',
        notConfirmedEmail: "L'email non corrisponde all'email di conferma.",
        exceededMembers: 'Superato il limite dei familiari.',
        childIsTooYoung: 'Non è stato possibile aggiungere un bambino di età inferiore a 6 mesi.',
        exceededAllergies: 'Le allergie hanno superato il limite di 5.',
        exceededIngredients: 'Gli ingredienti esclusi hanno superato il limite di 5.',
        tooYoung: 'Devi avere più di 18 anni per procedere con la registrazione.',
        weeklyRecipeNotFound: 'Ricetta della settimana non trovata.',
        codeGenerationNotFamilyLeader: 'Devi essere un capofamiglia per procedere con la generazione del codice.',
        codeGenerationImpossibleForFamilyLeader: 'Impossibile generare un codice di invito per un capofamiglia.',
        codeGenerationImpossibleForDifferentFamily: 'Impossibile generare un codice di invito per una famiglia diversa.',
        planInCreation: 'Aspetta che Planter termini di aggiornare il piano familiare prima di eseguire qualsiasi altra azione.',
        PlanNotDeleted: 'Il piano non è stato eliminato.',
        BirthdayShouldBeGreaterThanThePreviousOne: 'Non è possibile inserire una data di nascita successiva a quella impostata.',
    },
    changeMeal: {
        headerTitle: 'Crea pasto sostitutivo',
        whomTitle: 'Per chi vuoi creare %{meal} sostitutiv%{lastLetter}',
        assignedMealComponents: 'Componenti assegnati %{meal}',
        chooseBalancedMeal: 'Scegli uno di questi pasti bilanciati o creane uno nuovo.',
        theBreakfast: 'la colazione',
        theLunch: 'il pranzo',
        theSnack: 'lo spuntino',
        theDinner: 'la cena',
        toBreakfast: 'alla colazione',
        toLunch: 'al pranzo',
        toSnack: 'allo spuntino',
        toDinner: 'alla cena',
        lastFemaleLetter: 'a',
        lastMaleLetter: 'o',
        choose: 'Scegli',
        createYourMeal: 'Crea il tuo pasto',
        howNutrientsWorks: 'Come funziona?',
        nutrientsInfo: 'Le icone dei gruppi alimentari andranno colorandosi sempre più intensamente man mano che aggiungerai ricette che li contengono.\n' +
            'Quando il loro apporto sarà tale da rendere il tuo pasto bilanciato all’interno della giornata, le icone si coloreranno in modo pieno.\n' +
            'In caso contrario resteranno spente, oppure parzialmente colorate.',
        createOwnMealDescription: 'Seleziona le ricette che vuoi aggiungere al tuo pasto.\nPlanter ti aiuterà a creare un’alternativa bilanciata.',
        yourMeal: 'Il tuo pasto',
        selectYourRecipe: 'Aggiungi un piatto',
        mealCreated: 'Il tuo pasto è stato creato.',
        emptyNutrientTitle: 'Apporto non soddisfatto',
        almostFullNutrientTitle: 'Apporto in parte soddisfatto',
        fullNutrientTitle: 'Apporto soddisfatto',
    },
    mobileViewWebInformation: {
        title: ['Planter sta già creando il', 'tuo piano', 'personalizzato!'],
        subtitle: [
            'Per visualizzarlo e scoprire tutte le altre funzioni di Planter, ',
            'scarica l’app',
            ' e accedi con la mail che hai usato in fase di iscrizione.',
        ],
        dowloadApp: 'Il tuo piano personalizzato è in arrivo! \nScarica l’app per visualizzarlo e scoprire tutte le altre funzioni di Planter.',
        manageSubscription: 'Gestisci il tuo abbonamento su Stripe'
    },
    recipePreview: {
        visualize: 'Per visualizzare questa ed altre ricette e scoprire tutte le altre funzioni di Planter, ',
        downloadApp: 'scarica l’app',
    },
    inAppPurchase: {
        title: 'Entra in Planter',
        description: 'Planter è un servizio a pagamento. Nel prezzo è compreso un piano personalizzato sulla base delle necessità tue e della tua famiglia. Potrai aggiungere i dati e le esigenze dei tuoi familiari in seguito, fino ad un massimo di',
        descriptionBold: '5 persone',
        haveDiscount: 'Hai un',
        discount: 'codice sconto',
        startTrial: 'Inizia la prova gratuita',
        purchase: 'Acquista',
        trialDisclaimer: 'Al termine della prova gratuita di 7 giorni l’abbonamento sarà attivo e si rinnoverà automaticamente. Potrai cancellarlo in ogni momento. Se non vuoi procedere, puoi cancellare i dati finora inseriti',
        trialDisclaimerTap: 'premendo qui.',
        restorePurchase: 'Ripristina un acquisto',
        termsAndCondition: 'Termini e condizioni',
        labels: {
            threeMonths: '3 mesi',
            sixMonths: '6 mesi',
            annual: '12 mesi',
            lifetime: 'per sempre',
            monthly: 'mese',
            weekly: 'settimana',
            currencyEur: '€',
            currencyDollar: '$',
            cheapest: 'Il più conveniente',
        },
        introduction: {
            title: 'Cosa ti offre Planter',
            trialTitle: '7 giorni di prova',
            trialDescription: 'Dopo i primi 7 giorni di prova gratuita si attiverà un abbonamento che potrai disdire quando vuoi.',
            recipesTitle: 'Migliaia di ricette vegetali',
            recipesDescription: 'Spiegate passo passo e alla portata di tutti.',
            planTitle: 'Menu settimanali personalizzati',
            planDescription: 'Un piano bilanciato creato su misura per te e per la tua famiglia.',
            askTitle: 'Nutrizionista on demand 7/7',
            askDescription: 'Risolvi i tuoi dubbi con il nostro team di esperti ogni volta che ne avrai bisogno.',
            childrenTitle: 'Attenzione per i più piccoli',
            childrenDescription: 'Indicazioni nutrizionali dettagliate per i bambini sin dallo svezzamento.',
            listTitle: 'Lista della spesa',
            listDescription: 'Acquisterai solo il necessario, e avrai sempre tutti gli ingredienti che ti servono.',
            continueWithPurchase: "Continua l'acquisto",
            warning: 'Attenzione',
            disclaimerNote: 'Planter non sostituisce il parere del tuo medico, che ti consigliamo di contattare per qualsiasi problematica o dubbio sul tuo stato di salute.',
            disclaimerHere: 'Qui trovi',
            disclaimerScientifics: 'le basi scientifiche a supporto del tuo piano.',
        },
        disclaimers: {
            title: 'Informazioni sul piano',
            plan: 'Il piano alimentare creato da Planter viene',
            planEmphasized: 'elaborato sulla base delle più aggiornate linee guida peer-reviewed',
            planFinal: 'in merito all’alimentazione vegetale.',
            doctor: "L'algoritmo alla base della formulazione del piano è stato creato da Planter sotto la guida della Dott.ssa Silvia Goggi, coniugando tali linee guida con la sua esperienza clinica.",
            algorithm: 'Tale algoritmo, infatti, è in grado di selezionare le ricette, aggiustare le dosi dei singoli ingredienti e creare un piano alimentare bilanciato. Puoi consultare gli articoli delle linee guida originali ai seguenti link:',
            vegPlateLinkDesc: 'VegPlate: A Mediterranean-Based Food Guide for Italian Adult, Pregnant, and Lactating Vegetarians.',
            planningWellBalancedDesc: 'Planning Well-Balanced Vegetarian Diets in Infants, Children, and Adolescents: The VegPlate Junior.',
            veganNutritionDesc: 'Vegan Nutrition for Mothers and Children: Practical Tools for Healthcare Providers.',
            footerNote: 'Planter non sostituisce il parere del tuo medico curante, che ti consigliamo di contattare per qualsiasi problematica o dubbio relativi al tuo stato di salute.',
        },
        warnings: {
            expiredSub: 'Siamo spiacenti, il tuo abbonamento è scaduto. Riattivalo per procedere.',
            issuePayment: 'Si è verificato un problema con il pagamento. Controlla i dati e riprova.',
            unknown: 'Siamo spiacenti, si è verificato un problema.',
        },
    },
    redeemCoupon: {
        title: 'Riscatta un regalo',
        subtitle: 'Inserisci il codice coupon che hai ricevuto via mail per riscattare il tuo regalo.',
        couponCode: 'Codice coupon',
        confirmBtn: 'Conferma',
        codeInputWrongFormat: 'Il codice inserito non è corretto.',
        error: {
            generic: 'Si è verificato un errore, riprova.',
            couponNotValid: 'Non è stato possibile riscattare questo coupon. Riprova o inserisci un altro coupon',
        },
    },
    redeemedCoupon: {
        title: ['Regalo', 'riscattato', 'con successo!'],
        subtitle: 'La scadenza del tuo abbonamento a Planter è stata prolungata.\nPuoi controllare lo stato del tuo abbonamento nella sezione Dettagli Fatturazione.',
    },
    redeemCouponForbidden: {
        title: 'Riscatta un regalo',
        subtitle: 'Disattiva il rinnovo automatico dell’abbonamento direttamente dal tuo smartphone e, una volta scaduto, torna a riscattare il tuo regalo.',
    },
    introRedeemCoupon: {
        title: 'Riscatta un regalo',
        emailSection: {
            subtitle: 'Crea un account oppure accedi a un account esistente per riscattare il tuo regalo, ottenere il tuo piano alimentare personalizzato, migliaia di ricette vegetali e il supporto del nostro team di nutrizionisti.',
        },
        countrySection: {
            subtitle: 'Inserisci il paese di residenza e accetta la Privacy Policy e i Termini e Condizioni per procedere.',
        },
        redeemGiftSection: {
            subtitle: 'Inserisci il codice coupon che hai ricevuto via mail per riscattare il tuo regalo.',
            codeInputWrongFormat: 'Il codice inserito non è corretto.',
        },
    },
};
export default it;
