var en = {
    hello: 'Hello world!',
    appName: 'Planter',
    appDescription: 'The plant-based meal plan designed to make your life easier.',
    yes: 'Yes',
    no: 'No',
    ok: 'OK',
    feminineThe: 'the',
    masculineThe: 'the',
    masculinePluralThe: 'the',
    femininePluralAndThe: 'and the',
    recipes: 'recipes',
    email: 'email',
    confirmEmail: 'confirm email',
    save: 'Save',
    country: 'country of residence',
    recipe: 'recipe',
    person: 'person',
    persons: 'people',
    people: 'people',
    buttonSave: 'Save',
    goBack: 'Back',
    today: 'Today',
    day: 'Day',
    fromTo: 'from - to',
    fromDate: 'from',
    toDate: 'to',
    of: 'of',
    hourAbbreviation: 'h',
    minuteAbbreviation: 'min',
    hour: 'hour',
    minute: 'minute',
    month: 'month',
    months: 'months',
    year: 'year',
    years: 'years',
    hours: 'hours',
    minutes: 'minutes',
    hourFirstLetterCapitalized: 'Hour',
    time: 'Time',
    days: 'Days',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    dateFormat: 'DD / MM / YYYY',
    all: 'All',
    noDaysSelected: 'None',
    and: 'and',
    exclamationMark: '!',
    delete: 'Delete',
    notFoundPage: 'The page doesn’t exist or you don’t have permission to view it.',
    somethingGoesWrong: 'Something went wrong.',
    warning: 'Warning',
    AppStoreIcon: 'App store',
    PlayStoreIcon: 'Play store',
    understood: 'I understand',
    saveChanges: 'Save changes',
    goBackward: 'Go back',
    goForward: 'Continue',
    roles: {
        familyMember: 'Family member',
        familyLeader: 'Admin',
        guests: 'Guests',
    },
    toast: {
        titleInvitesToPlan: 'Link copied.',
        titleDisableMeal: 'Your meal has been disabled.',
        titleDisableRecipe: 'The recipe has been disabled.',
        titleEnableMeal: 'Your meal has been enabled.',
        titleEnableRecipe: 'The recipe has been enabled.',
        titleAddGuestsToMeal: 'Guests have been added to your meal.',
        titleAddGuestsToRecipe: 'Guests have been added to the recipe.',
    },
    modal: {
        descriptionFamilyCheck: 'The code entered has already been used. Continue with the same code or create a new account.',
        buttonConfirmFamilyCheck: 'Continue',
        buttonDeniedFamilyCheck: 'New account',
    },
    splashscreen: {
        title: 'The plant-based meal plan designed to make your life easier.',
        buttonTitle: 'Start',
        slowMessage: 'Loading',
        signUp: {
            title: 'Join Planter',
            description: 'Create an account or log in to an existing account to get your personalised meal plan, thousands of plant-based recipes and advice from our team of nutritionists.',
        },
        accessCode: {
            title: 'Verify your email address',
            description: 'Please enter the code we sent to %{email}.',
            noEmailRecived: 'email not received?',
            resendcheckSpamEmail: 'Check your spam folder or',
            resendEmail: 'send again',
        },
        intro: {
            title: 'The plant-based meal plan designed to make your life easier',
            buttonTitle: 'Log in',
            buttonTrial: 'Start your 7-day free trial',
            alreadyHaveAccount: 'Already have an account?',
        },
        welcome: {
            title: 'Hello ',
            secondTitle: ', welcome to Planter!',
            subTitle: 'Get your very own personalised meal plan now, check out the thousands of plant-based recipes and feel free to ask our team of nutritionists for advice any time.',
            buttonTitle: 'Start',
        },
    },
    menu: {
        plan: 'Plan',
        list: 'List',
        explore: 'Explore',
        ask: 'Ask',
    },
    searchPlaceholder: 'Search for a recipe',
    favorite: 'Favourites',
    otpValidation: {
        title: 'Verify your email address',
        description: 'Please enter the code we sent to \n',
        secondDescription: 'email not received?\nCheck your spam folder or ',
        titleLink: 'send again',
        buttonTitle: 'Continue',
    },
    emailCheckScreen: {
        title: 'Join Planter',
        description: 'Create an account or log in to an existing account to get your personalised meal plan, thousands of plant-based recipes and advice from our team of nutritionists.',
        buttonTitle: 'Continue',
        emailRequiredError: 'Compulsory field.',
        emailFormatError: 'Incorrect format.',
    },
    serveSelectionScreen: {
        title: 'Choose time zone',
        description: 'Planter will use this information to add new days to your meal plan at night, while you sleep.',
        europe: 'Europe (CET)',
        northAmerica: 'America (CST)',
        requiredServerError: 'Select a time zone to proceed.',
    },
    countryScreen: {
        title: 'Join Planter',
        description: 'Create an account or log in to an existing account to get your personalised meal plan, thousands of plant-based recipes and advice from our team of nutritionists.',
        buttonTitle: 'Continue',
        emailFormatError: 'Incorrect format.',
        labelCountry: 'Country of residence',
        termsAndConditions: 'Terms and Conditions',
        termsAndUseConditions: 'Terms and Conditions of Use',
        bottomSubTitle: 'By continuing, I accept the',
        termsOfUse: 'Terms of Use',
        bottomSubTitleThe: ', the',
        termsOfSale: 'Terms of Sale',
        bottomSubTitleAnd: 'and the',
        privacyPolicy: 'Privacy Policy',
        bottomSubTitlePlanter: '.',
        secondBottomSubTitle: 'Please send me Planter’s newsletter with updates and promotional content.',
        acceptPrivacyPolicy: "By continuing, I accept Planter's */ppPrivacy Policy/pp*.",
        acceptTerms: 'By continuing, I accept the */cuTerms and Conditions of Use/cu* and the */cvTerms of Sale/cv*.',
        accept: 'By continuing, I accept',
    },
    placeholder: {
        email: 'e-mail',
        height: 'Height',
        growthSpeed: 'Growth rate',
        portion: 'Portion size',
        suggestedPortion: 'Recommended portion size',
    },
    back: 'Back',
    forward: 'Next',
    modify: 'Edit',
    continue: 'Continue',
    done: 'Done',
    confirm: 'Confirm',
    cancel: 'Cancel',
    admin: 'Admin',
    otherComponents: 'Other members',
    //** SCREEN **//
    walkThrough: {
        counterText: '%{x} of %{y}',
        recipesStepTitle: 'Thousands of plant-based recipes anyone can master.',
        recipesStepSubtitle: 'With Planter on hand to guide you every step of the way.',
        shoppingListStepTitle: 'Your shopping list updated in real time.',
        shoppingListStepSubtitle: 'Did you know that 17% of the food we buy worldwide goes to waste? But Planter makes sure you only buy what you really need. And saves you money.',
        mealsPlanStepTitle: 'A wide variety of nutritionally balanced menus.',
        mealsPlanStepSubtitle: 'Tailored to all your family’s needs.\nTo keep your taste buds happy.',
        supportChatStepTitle: 'We’ll solve all your doubts.',
        supportChatStepSubtitle: 'Get answers to all your questions 7 days a week from our team of plant-based nutrition experts.',
        start: 'Let’s get started!',
    },
    selectAddLabel: 'Added by you',
    selectAddButtonLabel: 'Add',
    memberRegistry: {
        counterText: '%{x} / %{y}',
        familyCheckTitle: 'Have you received a family code?',
        familyCheckSubtitle: 'Please enter the family code you received below, otherwise select “I don’t have a code”.',
        familyCheckInputPlaceholder: 'Family Code',
        familyCheckNoCode: 'I don’t have a code',
        familyCheckCodeDoesNotExist: 'The code entered doesn’t exist or is already in use.',
        familyCheckInputLengthError: 'Enter the code',
        inputNameTitle: 'What’s your name?',
        inputNamePlaceholder: 'Edit name',
        inputNameSubtitle: 'Nice to meet you!',
        inputNameSubtitleFamilyMember: 'Pleased to meet them!',
        inputNameRequiredError: 'You must enter a name.',
        inputNameValidationError: 'Incorrect format.',
        inputNameMaxLengthError: 'The name must not include more than 30 characters.',
        inputNameMinLengthError: 'The name must include at least 3 characters.',
        modifyName: 'Edit name',
        addMember: 'Add member',
        inputBirthdateTitle: 'What’s your date of birth?',
        inputBirthdateSubtitle: 'Planter needs to know your age to calculate your nutritional requirements as accurately as possible.',
        inputBirthdateSubtitleFamilyMember: 'Planter needs to know their age to calculate their nutritional requirements as accurately as possible.',
        inputBirthdateRequiredError: 'You must enter a date of birth.',
        inputBirthdateInfantAgeError: 'It is best to wait 6 months before introducing solid foods.',
        inputBirthdateAdultToMinorAgeError: 'The user cannot be set as a minor - please create a new profile to continue.',
        inputBirthdateAdultAgeError: 'You must be an adult to use Planter.',
        inputBirthdateInFuture: 'The date of birth entered cannot be in the future.',
        inputBirthdateNotValid: 'The date of birth entered is not valid.',
        modifyBirthdate: 'Edit date of birth',
        inputGenderTitle: 'What’s your gender?',
        inputGenderSubtitle: 'Your hormonal balance is one of the various factors that affect your body composition, how much energy you burn and some of your nutritional needs.',
        inputGenderSubtitleFamilyMember: 'Their hormonal balance is one of the various factors that affect their body composition, how much energy they burn and some of their nutritional needs.',
        inputGenderExpandedNavBarTitle: 'Other',
        inputGenderFirstOptionLabel: 'Male',
        inputGenderSecondOptionLabel: 'Female',
        inputGenderThirdOptionLabel: 'Other',
        inputGenderRequiredError: 'Please enter a gender.',
        inputGenderExpandableFirstOptionLabel: 'Trans man',
        inputGenderExpandableSecondOptionLabel: 'Trans woman',
        inputGenderExpandableThirdOptionLabel: 'I prefer not to say',
        inputGenderExpandableThirdOptionLabelFamilyMember: 'They prefer not to say',
        modifyGender: 'Edit gender',
        inputHeightTitle: 'How tall are you?',
        inputHeightSubtitle: 'Enter your height.\nThis will help Planter tailor the correct portion sizes for you.',
        inputHeightSubtitleFamilyMember: 'Enter their height.\nThis will help Planter tailor the correct portion sizes for them.',
        inputHeightRequiredError: 'Please enter a height.',
        inputHeightMinError: 'The minimum accepted height is 50 cm.',
        inputHeightMaxError: 'The maximum accepted height is 220 cm.',
        modifyHeight: 'Edit height',
        inputPhysicalActivityTitle: 'Which of the following statements best describes your level of physical activity?',
        inputPhysicalActivitySubtitle: "Physical activity is one of the main factors that determine a person's energy requirements.",
        inputPhysicalActivityRequiredError: 'Please complete the physical activity field.',
        inputPhysicalActivityFirstOptionLabel: 'I lead a sedentary lifestyle, I drive everywhere or take public transport and I am unable to exercise regularly.',
        inputPhysicalActivitySecondOptionLabel: 'I take at least 10,000 steps a day (about 6 km) and/or I work out at least twice a week for an hour or more.',
        inputPhysicalActivityThirdOptionLabel: 'I walk or cycle as much as possible, plus I exercise for at least an hour 4 or more times a week.',
        inputPhysicalActivityFourthOptionLabel: 'I do high-intensity training every day and/or I am a professional athlete.',
        modifyPhysicalActivity: 'Edit activity level',
        inputAllergiesTitle: 'Are you allergic to anything?',
        inputAllergiesSubtitle: 'Planter won’t include any recipes that contain ingredients you can’t eat.',
        inputAllergiesSubtitleFamilyMember: 'Planter won’t include any recipes that contain ingredients they can’t eat.',
        inputAllergiesFirstOptionLabel: 'Gluten',
        inputAllergiesSecondOptionLabel: 'Soy',
        inputAllergiesThirdOptionLabel: 'Nuts',
        inputInfiniteScrollLabelAllergies: 'Other allergies',
        modifyAllergies: 'Edit allergies',
        modifyGrowthSpeed: 'Edit growth rate',
        inputInfiniteScrollLabelAvoidIngredients: 'Edit ingredients to avoid',
        inputAvoidIngredientsTitle: 'Are there any ingredients that you want to avoid?',
        inputAvoidIngredientsSubtitle: 'Planter will also exclude any recipes containing these ingredients. Don’t worry! You can always edit these settings later on.',
        inputAvoidIngredientsSubtitleFamilyMember: 'Planter will also exclude any recipes containing these ingredients. Don’t worry! You can both edit these settings later on.',
        modifyAvoidIngredients: 'Edit ingredients to avoid',
        modifyPortions: 'Edit portion sizes',
        inputPortionTitle: 'Edit portion sizes',
        inputPortionSubtitle: 'How much do you want to eat?',
        portionEatLess: 'Less, please!',
        portionEatMore: 'More, please!',
        portionValues: {
            portionEatMuchLess: 'A lot less',
            portionEatLess: 'Less',
            portionEatBitLess: 'A little bit less',
            portionEatStandard: 'A normal amount',
            portionEatBitMore: 'A little bit more',
            portionEatMore: 'More',
            portionEatMuchMore: 'A lot more',
        },
        portionAppetite: 'There are lots of things that affect your appetite so you may find it changes over time. But don’t worry, you can edit the portion sizes as many times as you like, whenever you like. Planter will always make sure that your meal plan is correctly balanced.',
        inputPortionDescription: 'Planter will generate portion sizes tailored to the information you have provided. But please remember that hunger is subjective. Use the keys below to adjust the portion sizes based on their appetite.',
        end: 'End',
        welcomePage: {
            title: 'Hello %{name}, welcome to Planter!',
            subtitle: 'Get your very own personalised meal plan now, check out the thousands of plant-based recipes and feel free to ask our team of nutritionists for advice any time.',
            startButtonLabel: 'Start',
            sidebar: {
                title: 'Settings',
                subtitle: 'Add up to 5 family members to your plan, or skip this step and complete it later in the Profile section.',
                maximumNumberOfMembers: 'You have reached the maximum number of members you can associate with your plan.',
                administrator: 'Admin',
                otherComponents: 'Other members',
                addComponentButtonLabel: 'Add',
                SkipButtonLabel: 'Skip',
                CreatePlanButtonLabel: 'Go to your plan',
            },
        },
        inputFamilyNameTitle: 'What’s their name?',
        inputFamilyBirthdateTitle: 'What’s %{name}’s date of birth?',
        inputFamilyGenderTitle: 'What’s %{name}’s gender?',
        inputFamilyGrowthSpeedTitleFamilyMember: 'What’s %{name}’s growth rate?',
        inputFamilyGrowthSpeedTitle: 'What’s your growth rate?',
        inputFamilyGrowthSpeedSubtitle: 'Planter will use this information to calculate their nutritional needs as accurately as possible.',
        inputFamilyGrowthSpeedFirstOptionLabel: 'Below average (< 25th percentile)',
        inputFamilyGrowthSpeedSecondOptionLabel: 'Average (25th - 75th percentile)',
        inputFamilyGrowthSpeedThirdOptionLabel: 'Above average (> 75th percentile)',
        inputFamilyGrowthSpeedDescription: 'Planter will use this information to calculate their nutritional needs as accurately as possible.',
        inputFamilyHeightTitle: 'How tall is %{name}?',
        inputFamilyPhysicalActivityTitle: 'Which of these statements best describes their level of physical activity?',
        inputFamilyPhysicalActivitySubtitle: "Physical activity is one of the main factors that determine a person's energy requirements.",
        inputFamilyPhysicalActivityFirstOptionLabel: 'They lead a sedentary lifestyle, drive everywhere or take public transport, and are unable to exercise regularly.',
        inputFamilyPhysicalActivitySecondOptionLabel: 'They take at least 10,000 steps a day (about 6 km) and/or they work out at least twice a week for an hour or more.',
        inputFamilyPhysicalActivityThirdOptionLabel: 'They walk or cycle as much as possible, plus they exercise for at least an hour 4 or more times a week.',
        inputFamilyPhysicalActivityFourthOptionLabel: 'They do high-intensity training every day and/or they are professional athletes.',
        inputFamilyAllergiesTitle: 'Is %{name} allergic to anything?',
        inputFamilyAvoidIngredientsTitle: 'Are there any ingredients that %{name} wants to avoid?',
        parentalResponsibilityCheckboxFirstPart: 'I hereby declare that I have parental authority/guardianship over the minor and I authorise the processing of their personal data for the purposes of the service, in accordance with the provisions of the',
        parentalResponsibilityCheckboxSecondPartCGU: 'Terms of Use',
        parentalResponsibilityCheckboxSecondPartCGV: 'Terms of Sale',
        parentalResponsibilityCheckboxThirdPart: 'and the',
        parentalResponsibilityCheckboxFourthPart: 'Privacy Policy.',
        parentalResponsibilityCheckboxComplete: 'I hereby declare that I have parental authority/guardianship over the minor and I authorise the processing of their personal data for the purposes of the service, in accordance with the provisions of the */cuTerms of Use/cu*, */cvTerms of Sale/cv* and the */ppPrivacy Policy/pp*.',
        editAnswerAlert: 'Warning',
        variationPlanAlert: 'Remember that the meal plan will be regenerated if you change the allergens and/or ingredients that one or more members of your family wants to avoid.',
    },
    addComponents: {
        title: 'Settings',
        description: 'Add up to 5 family members to your plan, or skip this step and complete it later in the Profile section.',
        buttonTitle: 'Go to your plan',
        fullFamily: 'You have reached the maximum number of members you can associate with your plan.',
        familyMember: 'Member',
        familyMembers: 'Members',
        membersAddedToPlan: 'added to the plan',
        editName: 'Edit name',
        editAge: 'Edit age',
        editAvoidIngredients: 'Edit ingredients',
        editAllergies: 'Edit allergies',
        editGender: 'Edit gender',
        editActivity: 'Edit physical activity',
        editGrowthSpeed: 'Edit growth rate',
        editHeight: 'Edit height',
    },
    infiniteScrollList: {
        reset: 'Reset',
        counterTitle: '%{x} of %{y} ingredients',
        counterTitleNoLimit: '%{x} ingredients',
        counterSubtitle: 'selected',
        selectedItemsSectionTitle: 'Selected',
        searchbarPlaceholder: 'Search',
    },
    explorePage: {
        weeklyRecipe: 'Recipe of the week',
        seeRecipe: 'View recipe',
        seeAll: 'View all',
        seasonalRecipes: 'Now in season.',
        featuredContents: 'Featured recipes.',
        allNewsForYou: 'Check out our favourites!',
        respectingNature: 'Fill up on nutrients.',
        sundayWithTheFamily: 'Weekend cooking.',
        sharingMeals: 'Let Planter inspire you.',
        youMightLike: 'You might also like',
        placeholderSearch: 'Search for a recipe',
        collection: 'Collection',
        featuredContentTitle: 'Featured recipes.',
        featuredContentSubtitle: 'Check out our favourites!',
        filterRecipe: 'recipe',
        filterRecipes: 'recipes',
        searchNotFoundIconDescription: 'No result found icon',
        searchNotFound: 'No result found',
        searchNotFoundDescription: 'Oops, your search didn’t produce any results.',
    },
    planPage: {
        waitingForPlan: {
            title: 'Planter is creating\nthe perfect meal plan\nfor you.',
            subtitle: 'Just a moment.',
        },
        day: 'Day',
        week: 'Week',
        sectionTitles: {
            BREAKFAST: 'Breakfast.',
            MORNING_SNACK: 'Morning snack.',
            LUNCH: 'Lunch.',
            SNACK: 'Afternoon snack.',
            AFTERNOON_SNACK: 'Afternoon snack.',
            DINNER: 'Dinner.',
            ADDED: 'Added by you.',
        },
        sectionSubTitles: {
            BREAKFAST: 'Kick start your day.',
            MORNING_SNACK: 'Recharge your batteries.',
            LUNCH: 'Boost your energy.',
            SNACK: 'Time to take a break.',
            AFTERNOON_SNACK: 'Time to take a break.',
            DINNER: 'Finish on a high.',
            ADDED: 'Not included in your plan.',
        },
        incompletePlanMessage: 'Plan incomplete for this day.',
        modifyRecipe: 'Edit recipe',
        modalModifyRecipeQuestion: 'What do you want to do with the %{recipeName} recipe?',
        changeRecipe: 'Substitute recipe',
        replace: 'Substitute',
        changeHeaderRecipe: 'Substitute recipe',
        changeRecipeDescription: 'Members assigned to %{recipeName}?',
        createMealDescription: 'Members assigned to %{meal}',
        createMeal: 'Create your meal',
        disableRecipe: 'Disable recipe',
        whoToDisableOnRecipe: 'Who do you want to disable the %{recipeName} recipe for?',
        whoToDisableOnRecipeDescription: 'Choose whether to disable the recipe for the whole family or just for one or more members.',
        whoToEnableOnRecipe: 'Who do you want to enable the %{recipeName} recipe for?',
        whoToEnableOnRecipeDescription: 'Choose whether to enable the recipe for the whole family or just for one or more members',
        modifyMeal: 'Edit meal',
        modalModifyMealQuestion: 'What do you want to do with %{meal} meal?',
        modalModifyMealQuestionFirstPart: 'What do you want to do',
        modalModifyMealQuestionBreakfast: 'with breakfast',
        modalModifyMealQuestionLunch: 'with lunch',
        modalModifyMealQuestionMorningSnack: 'with the morning snack',
        modalModifyMealQuestionSnack: 'with the afternoon snack',
        modalModifyMealQuestionDinner: 'with dinner',
        modalModifyMealQuestionAdded: 'with the recipes added by you',
        modalModifyMealQuestionSecondPart: '?',
        createSostitutiveMeal: 'Create a substitute meal',
        disableMeal: 'Disable meal',
        whoToDisableOnMealFirstPart: 'Who do you want to disable',
        whoToDisableOnMealSecondPart: ' for?',
        whoToDisableOnMealDescription: 'Choose whether to disable the meal for the whole family or just for one or more members.',
        whoToEnableOnMealFirstPart: 'Who do you want to enable',
        articleMeals: {
            BREAKFAST: 'breakfast',
            MORNING_SNACK: 'the morning snack',
            LUNCH: 'lunch',
            SNACK: 'the afternoon snack',
            AFTERNOON_SNACK: 'the afternoon snack',
            DINNER: 'dinner',
            ADDED: 'added',
        },
        whoToEnableOnMealSecondPart: ' for?',
        whoToEnableOnMealDescription: 'Choose whether to enable the meal for the whole family or just for one or more members.',
        whoToSubstituteRecipe: 'Who do you want to substitute the %{recipeName} recipe for?',
        howManyGuestsForMeal: 'How many guests do you want to add to %{meal}?',
        howManyGuestsForRecipe: 'How many guests do you want to add to the recipe %{recipeName}?',
        howManyGuestsAreThereForMeal: 'How many guests are present at the %{meal}?',
        howManyGuestsAreThereForRecipe: 'How many guests are present at recipe %{recipeName}?',
        forWhomToCreate: 'Who do you want to create the substitute meal for?',
        recipeDisabledSuccessfully: 'The recipe has been disabled.',
        mealDisabledSuccessfully: 'Your meal has been disabled.',
        recipeSubstitutedSuccessfully: 'The recipe has been substituted.',
        disableEntity: 'disable %{entity}',
        allComponents: 'All members',
        selectBalancedMealTitle: 'Choose one of these balanced meals or create a new one',
        choose: 'Choose',
        createYourMeal: 'Create your meal',
        nutrientsGroup: 'Food groups',
        yourMeal: 'Your meal',
        selectAMeal: 'Add a dish',
        select: 'Select',
        balancedRecipesFound: 'balanced %{noun} for this meal',
        todayTips: 'Today’s tips',
        forYourPlanning: 'For your meal plan',
        addGuests: 'Add guests',
        manageGuests: 'Manage guests',
        guestsManagement: 'Manage guests',
        staticAdvice: {
            b12VitaminTitle: 'Vitamin B12',
            b12VitaminDescription: 'Have you taken your vitamin B12 today?',
            omega3Title: 'Omega-3',
            omega3Description: 'Two tablespoons of ground flax or chia seeds, or a tablespoon of flaxseed oil will help you meet your omega-3 requirements, regardless of your age.',
        },
        loadingAlternativeMeal: {
            title: 'Planter is looking for other balanced alternatives.',
            description: 'Just a moment, please.',
        },
        remove: 'Remove',
        enableRecipe: 'Enable recipe',
        enableMeal: 'Enable meal',
    },
    listPage: {
        waitingForPlan: {
            title: 'The shopping list\nwill be created\nalong with the meal plan.',
            subtitle: 'Just a moment.',
        },
        title: 'Shopping list',
        ingredients: 'Ingredients',
        toBuy: 'to buy',
        subtitleFirstPart: 'You’ll probably find you’ve already got quite a few of these ingredients.',
        subtitleSecondPart: 'But check before you go shopping just to be safe!',
    },
    subscription: {
        firstPage: {
            title: 'Here’s what you get',
            btnLabel: 'Start your free trial',
            features: {
                first: {
                    title: '7-day trial',
                    description: 'Your subscription will automatically start when your initial 7-day free trial finishes but don’t worry, you can cancel it any time.',
                },
                second: {
                    title: 'Thousands of plant-based recipes',
                    description: 'Explained step by step – no special skills required!',
                },
                third: {
                    title: 'Personalised weekly meal plans',
                    description: 'A well-balanced meal plan tailored to all the family.',
                },
                fourth: {
                    title: 'Nutritionist on demand 7 days a week',
                    description: 'Need advice? Our team of experts is here to answer all your questions.',
                },
                fifth: {
                    title: 'Expert advice for kids',
                    description: 'Get detailed nutritional info for kids of all ages from weaning onwards.',
                },
                sixth: {
                    title: 'Shopping list',
                    description: 'Only buy what you really need and never run out of ingredients.',
                },
            },
        },
        secondPage: {
            title: 'Join Planter',
            subtitleFirstPart: 'Planter is a paid-for service. The fee includes a personalised plan tailored to all your family’s needs.\nYou can add your family’s information and requirements later, for a maximum of',
            subtitleSecondPart: '5 people',
            saleCodeFirstPart: 'Do you have a',
            saleCodeSecondPart: 'discount code',
            freeTrial: 'Start your free trial',
            footer: {
                descriptionFirstPart: 'Your subscription will start when your 7-day free trial ends and then automatically renew. Don’t worry, you can cancel it any time. If you don’t want to continue, you can delete all your data',
                descriptionSecondPartMobile: 'by tapping here',
                descriptionSecondPartWeb: 'by clicking here',
                restorePurchase: 'Restore a purchase',
                termsAndConditions: 'Terms and conditions',
            },
            mostConvenientLabel: 'Best value',
        },
        paymentMade: 'Payment successful',
    },
    profilePage: {
        profileManagement: 'Account',
        subscriptionDetails: 'Billing details',
        payment: 'Payments',
        notifications: 'Notifications',
        reminder: 'Reminders',
        b12Vitamin: 'Vitamin B12',
        omega3: 'Omega-3',
        notificationsAndReminder: 'Reminders and notifications',
        notificationsAndReminderTitle: 'Reminders and\nnotifications',
        b12VitaminNotificationDescription: 'Remember to take your\nvitamin B12 supplement.',
        omega3NotificationDescription: 'Remember to take one tablespoon of flaxseed oil or two tablespoons of ground flaxseed or chia seeds throughout the day. You can sprinkle them over your favourite recipes.',
        dontRemindMeAgain: "Don't remind me again",
        planInfo: 'About your plan',
        languageAndUnitOfMeasure: 'Language and units',
        familyMembers: 'Settings',
        inviteFriend: 'Invite a friend',
        help: 'Get support',
        termsOfService: 'Terms and conditions',
        termsOfUse: 'Terms of Use',
        termsOfSale: 'Terms of Sale',
        privacyPolicy: 'Privacy policy',
        cookie: 'Cookies',
        logout: 'Log out',
        logoutModal: {
            title: 'Logout',
            description: 'Are you sure you want to log out of your account?',
        },
        deleteAccount: 'Delete account',
        deleteAccountModal: {
            title: 'Delete account',
            description: 'Are you sure you want to delete your account?',
        },
        deleteAccountSidebar: {
            title: '%{name}, do you want to delete your account?',
            description: 'Your account and all the data associated with it will be deleted now. If you have an active subscription to Planter, you will no longer be able to access it, even if the period you have paid for hasn’t finished yet.',
            deleteAccountconsequences: {
                title: 'If you delete your account:',
                firstConsequence: 'You won’t be able to access the meal plan, recipes and Planter chat via this account.',
                secondConsequence: 'The information stored in your account, such as all your family’s food preferences and the chat history with the nutritionists, will be permanently deleted.',
                thirdConsequence: 'If you purchased Planter at a special price, you may not be entitled to the same conditions in the future.',
            },
        },
        subscriptionDetailsPage: {
            title: 'Billing details',
            subtitle: 'The ways to manage your subscription or free trial vary depending on your sign-up method.',
            list: {
                first: {
                    firstPart: 'If you subscribed via the website',
                    secondPart: 'planter.eco',
                    thirdPart: 'and paid via Stripe, you can learn how to manage your subscription',
                    fourthPart: 'here',
                    fifthPart: 'Should you encounter any difficulties, feel free to contact us at',
                    sixthPart: 'support@planter.eco',
                },
                second: {
                    firstPart: 'If you subscribed via the',
                    secondPart: 'App Store',
                    thirdPart: ', you can manage your subscription in Settings → Your name → Subscriptions.',
                },
                third: {
                    firstPart: 'If you subscribed via',
                    secondPart: 'Google Play',
                    thirdPart: ', you can manage your subscription in the Play Store → Your account → Subscriptions.',
                },
            },
            attention: {
                attention: 'Please note',
                explanation: {
                    firstPart: 'the fact that you do not see Planter among your Apple or Google subscriptions does not mean that your subscription has been deactivated (otherwise you would see it as expired or expiring). It means that you activated it via the website, so it is normal that you do not find it in the subscriptions section of your store.',
                },
            },
            viewStripePortal: 'Manage your subscription with Stripe',
        },
        sections: {
            modifyEmail: 'Edit email address',
            modifyCountry: 'Edit country',
            updateEmailTitle: 'Update email address',
            updateEmailSubtitle: 'Please enter a new e-mail address.',
        },
        notificationHistory: {
            title: 'Notifications',
            seeRecipe: 'View recipe',
            notFound: 'No notifications.',
        },
        familyMembersSection: {
            title: 'Your profile',
            componentsAndPreferences: 'Settings',
            description: 'Add or remove members from your meal plan and update each member’s personal data and settings.',
            addMemberNotificationFemale: '%{name} has been added to the plan.',
            addMemberNotificationMale: '%{name} has been added to the plan.',
            addMemberNotificationUnknownGender: '%{name} has been added to the plan.',
            infoModal: {
                button: 'Info',
                title: 'Important Information!',
                description: 'Remember that if you change the allergies and ingredients you want to avoid, your current meal plan will be deleted and regenerated.',
            },
            allergiesDislikedIngredientsModal: {
                title: 'Warning',
                description: 'Remember that the meal plan will be regenerated if you change the allergens and/or ingredients that one or more members of your family wants to avoid.',
            },
            changePortionModal: {
                title: 'Edit portion sizes',
                description: 'If you change your settings, the meal plan will be updated. Confirm change?',
            },
        },
        PlanInformationPage: {
            title: 'About your plan',
            firstParagraph: {
                firstPart: 'Your Planter meal plan is',
                secondPart: 'generated in accordance with the very latest peer-reviewed guidelines',
                thirdPart: 'for plant-based diets.',
            },
            secondParagraph: 'Dr. Silvia Goggi, a physician specialising in Food Science, created the Planter app algorithm by combining these guidelines with her clinical experience.',
            thirdParagraph: 'This algorithm can select recipes, adjust the quantities of individual ingredients and create a well-balanced meal plan. Consult the articles about the original guidelines at the following links:',
            firstBookTitle: 'VegPlate: A Mediterranean-Based Food Guide for Italian Adult, Pregnant, and Lactating Vegetarians.',
            secondBookTitle: 'Planning Well-Balanced Vegetarian Diets in Infants, Children, and Adolescents: The VegPlate Junior.',
            thirdBookTitle: 'Vegan Nutrition for Mothers and Children: Practical Tools for Healthcare Providers.',
            lastParagraph: 'Planter is not meant to be a replacement for your GP’s advice. We recommend consulting your GP about any health issues or concerns you may have.',
        },
        languageAndUnitOfMeasurePage: {
            title: 'Language and units',
            subtitle: 'Choose the language you want Planter to be set up, and the units of measurement you want the ingredients to be expressed in. ',
            language: 'Language',
            languages: {
                italian: 'Italian',
                englishUK: 'English',
                englishUS: 'English (US)',
            },
            unitOfMeasure: 'System of measurement',
            unitOfMeasures: {
                metric: 'Metric (grams and liters)',
                imperial: 'US imperial (ounces and cups)',
            },
            saveButton: 'Save',
            savedChanges: 'Settings saved successfully',
            errorInSavingChanges: 'Unable to save settings properly',
        },
    },
    invitesToThePlan: {
        navBarTitle: 'Invite to the plan',
        title: 'Invite %{name} to Planter',
        description: 'This code will give %{name} free access to Planter. They’ll be able to view your family meal plan, shopping list and all the recipes.',
        labelCopyLink: 'Copy code',
        labelShare: 'Share',
    },
    searchResultPage: {
        subtitle: 'Results for',
    },
    favouritesPage: {
        favourites: 'Favourite recipes',
        noResult: 'No recipe saved in favourites.',
    },
    memberProfile: {
        addMemberButton: 'Invite to the plan',
        deletedMemberButton: 'Remove member',
        deletedMemberModal: {
            title: 'Remove member',
            description: 'You are removing a member from the shared plan. Do you want to continue with this change?',
            notification: '%{name} has been removed from the plan',
        },
        emptyItem: 'None',
        emptyAllergies: 'None',
        name: 'Name',
        titleBirthDate: 'Edit date of birth',
        titleGender: 'Edit gender',
        height: 'Edit height',
        titleActivity: 'Edit physical activity',
        titleAllergies: 'Edit allergies',
        excludedIngredients: 'Edit ingredients to avoid',
        growthSpeed: 'Edit growth speed',
        portion: 'Edit portion sizes',
        profileScreenTitle: 'Edit information',
    },
    notificationAlert: {
        title: 'Error',
        codeError: 'Wrong code.',
        mailVerified: 'email verified.',
        mailResent: 'We have sent a new email.',
        genericError: 'An error has occurred, please try again.',
        copyToClipboard: 'Copied to Clipboard.',
    },
    bottomTab: {
        plan: 'Plan',
        list: 'List',
        explore: 'Explore',
        ask: 'Ask',
        profile: 'Profile',
    },
    recipeDetail: {
        showDosesMembers: 'Individual quantities',
        notifyIngredientsDescription: 'This recipe contains ingredients that aren’t compatible with the settings input by you and/or your family members.',
        ingredients: 'Ingredients',
        preparation: 'Method',
        notes: 'Notes',
        cereals: 'Grains',
        proteicFood: 'Protein foods',
        fruits: 'Fruit',
        vegetables: 'Vegetables',
        vegetableFats: 'Fats',
        foodGroups: 'Food groups',
        weaningAlertTitle: 'For babies starting on solids',
        tipTitle: 'Planter’s tips',
        nutrientsGroup: 'Food groups',
        recipeFor: 'Recipe for',
        personNumber: 'No. people',
        minutes: 'min',
        optional: 'Optional',
        people: 'People',
        date: 'Date',
        meal: 'Meal',
        addToPlan: 'Add to the plan',
        selectMembersTitle: 'Who do you want to add the recipe for?',
        guests: 'Guests',
        quest: 'Guest',
        planDateSelectTitle: 'When would you like to make the recipe?',
        componentsValue: '%{count} members',
        addedToPlan: 'added to the plan',
        hasBeenAddedToPlan: 'The recipe has been added to the plan.',
        planInformationSectionTitle: 'Planned for',
        shareError: 'Recipe cannot currently be shared',
        shareSucceeded: 'Recipe successfully shared.',
        shareMessage: 'Have a look at this Planter recipe! %{url}',
        shareLinkTitle: 'Check out this recipe on Planter!',
    },
    plan: {
        pendingTitle: 'Planter is creating\nthe perfect meal plan\nfor you.',
        pendingDescription: 'Just a moment.',
        editMeal: 'Edit meal',
        whichSnackToEdit: 'What do you want to do with the afternoon snack?',
        forWhomToAddRecipe: 'Who do you want to add the recipe for?',
        guests: 'Guests',
        guest: 'Guest',
        forWhomToDisable: 'Who do you want to disable the afternoon snack for?',
        forWhomToDisableMealDescription: 'Choose whether to disable the meal for one member or the whole family.',
        forWhomToDisableRecipeDescription: 'Choose whether to disable the recipe for the whole family or just one or more members.',
        forWhomToCreate: 'Who do you want to create the substitute lunch for?',
        disableMealHeader: 'Disable meal',
        disableMealFooterDescription: 'disable meal',
        disableRecipeFooterDescription: 'disable recipe',
        enableMealFooterDescription: 'enable meal',
        enableRecipeFooterDescription: 'enable recipe',
        mealReplacement: 'substitute meal',
        recipeReplacement: 'substitute recipe',
        manageMealGuestsFooterDescription: 'added to the meal',
        manageRecipeGuestsFooterDescription: 'added to the recipe',
    },
    askPage: {
        solveYourDoubts: 'Need advice?\nWe are here to help!',
        chatWithUsTitle: 'Do you want to contact a nutritionist?',
        chatWithUsBtn: 'Write to our team',
        frequentQuestionsTitle: 'FAQs',
        frequentQuestionsFirstDescription: 'Do you need an urgent answer to your question?',
        frequentQuestionsSecondDescription: 'You can find the FAQs here',
        frequentQuestionsMobileDescription: 'Check out our FAQs',
        faqPageTitle: 'FAQs',
    },
    maintenancePage: {
        title: 'We are currently doing maintenance work to make Planter even better.',
        titleMobile: 'We are currently doing maintenance work to make Planter even better.',
        subtitle: 'The service will be available again soon.',
        altImage: 'Dishes',
    },
    profile: {
        title: 'Hello,',
        exit: 'Log out',
        version: 'VERSION %{version} (%{buildNumber})',
        titleSectionFavourite: 'Your favourite recipes.',
        subTitleSectionFavourite: 'The ones you liked the most.',
        account: {
            title: 'Account',
            profileManagerScreen: {
                buttonTitle: 'Delete account',
            },
            changeCountry: {
                titleHeader: 'Change country of residence',
            },
            changeEmail: {
                titleHeader: 'Update email address',
                warningText: 'After changing your email, you will be logged out and will need to log back into the app with the new email to view its contents.',
                subTitle: 'Please enter a new e-mail address.',
                email: 'email address',
                confirmEmail: 'Confirm email address',
                modal: {
                    title: 'Update email address',
                    description: 'Are you sure you want to change your email address? You will be logged out.',
                },
            },
        },
        plan: {
            title: 'Meal plan',
            components: 'Settings',
            recurrentAbsenceTitle: 'Meal management',
            recurrentAbsenceDescription: 'Select one or more meals throughout the week that you and/or your family members do not usually eat at home.',
            editAbsences: 'Edit',
            confirmDiscardChanges: 'Do you want to exit without saving\nchanges?',
            allComponents: 'All members',
            recurrences: {
                meals: {
                    BREAKFAST: 'Breakfast',
                    MORNING_SNACK: 'Morning snack',
                    LUNCH: 'Lunch',
                    SNACK: 'Afternoon snack',
                    DINNER: 'Dinner',
                    ALL_MEALS: 'All meals',
                },
                days: {
                    0: 'Sunday',
                    1: 'Monday',
                    2: 'Tuesday',
                    3: 'Wednesday',
                    4: 'Thursday',
                    5: 'Friday',
                    6: 'Saturday',
                },
                noAbsence: 'No deactivated meals',
                everyDay: 'Every day',
                onlyLeaderCanManage: "Only the administrator can perform this operation",
            },
        },
        invitations: {
            title: 'Invitations and credits',
            inviteFriend: 'Invite a friend',
        },
        support: {
            title: 'Support',
            getSupport: 'Get support',
        },
        legal: {
            title: 'Information',
            termsService: 'Terms and conditions',
            privacy: 'Privacy policy',
        },
        deleteAccount: {
            title: 'Delete account',
            description: 'Are you sure you want to delete your account? The operation cannot be reversed.',
        },
    },
    filters: {
        all: 'All',
        otherElements: 'others',
        other: '+ other',
        element: '+ others',
        indifferent: 'I don’t mind',
        filters: 'Filters',
        reset: 'Reset',
        showRecipes: 'View recipes',
        resultsFor: 'Results for: ',
        noResults: 'Oops, your search didn’t produce any results!',
        general: {
            ingredients: 'Ingredients',
            categories: 'Categories',
            fromWorld: 'From around the world',
            seasonalRecipes: 'Now in season',
            preparationTime: 'Preparation time',
            nutrients: 'Food groups',
            preferred: 'Favourites',
        },
        preparationTime: {
            short: 'Short',
            medium: 'Medium',
            long: 'Long',
            shortTime: '(< 20 minutes)',
            mediumTime: '(20-60 minutes)',
            longTime: '(60+ minutes)',
        },
        chips: {
            preparationTime: {
                short: 'P.t. Short',
                medium: 'P.t. Medium',
                long: 'P.t. Long',
            },
            seasonalRecipes: 'Only in season now',
            notSeasonalRecipes: 'Not in season',
        },
    },
    tooSmallScreen: {
        title: 'Your screen is too small to display all the content. Download the app!',
    },
    datePicker: {
        headerTitle: 'Select one or more days',
        selectOneDate: 'Choose date',
    },
    alerts: {
        errorTitle: 'Error',
        errorMesage: 'The web page cannot currently be opened - please try again later',
        permissionRefused: 'You previously refused to give your permission - go to settings to change your choice.',
        goToSettings: 'Go to settings',
        featureUnavailable: 'Function not available.',
        featureUnavailableMessage: 'This function is not available on your smartphone.',
        close: 'Close',
    },
    editUserPreferences: {
        portions: 'Portion sizes',
        title: 'Edit portion sizes',
        familyLeaderEditPreferencesTitle: 'How much do you want to eat?',
        familyMemberEditPreferencesTitle: 'How much do they want to eat?',
        familyLeaderMainDescription: 'Planter will generate portion sizes for you tailored to the information you have provided. However, you know how hungry you are better than anyone else. Use the keys below to adjust the portion sizes based on your appetite.',
        familyMemberMainDescription: 'Planter will generate portion sizes tailored to the information you have provided. But please remember that hunger is subjective. Use the keys below to adjust the portion sizes based on their appetite.',
        userPreferences: {
            eatMore: 'More, please!',
            eatLess: 'Less, please!',
            descriptionTxt: 'There are lots of things that affect your appetite so you may find it changes over time. But don’t worry, you can edit the portion sizes as many times as you like, whenever you like. Planter will always make sure that your meal plan is correctly balanced.',
        },
        editUserPreferencesModal: {
            title: 'Edit meal plan',
            description: 'If you change your settings, the meal plan will be updated. Confirm change?',
        },
    },
    list: {
        titleHeader: 'Shopping list',
        titleLoader: 'The shopping list\nwill be created\nalong with the meal plan.',
        descriptionLoader: 'Just a moment.',
        subTitle: 'Ingredients',
        description: 'to buy',
        subtitle: 'You’ll probably find you’ve already got quite a few of these ingredients. But check before you go shopping just to be safe!',
        selectedIngredientsDescription: 'you have',
        foodStorageTitle: 'Shopping Lists',
        foodStorageDescription: 'Create one or more shopping lists.\n' +
            'They will be shared among all family members and will be constantly updated according to your plan.',
        showList: 'See list',
        atLeastOneFoodStorageError: 'You must have at least one active list.',
    },
    uploadImage: {
        takeAPhoto: 'Take a photo',
        chooseAPhoto: 'Choose photo',
        chooseFromLibrary: 'Choose from library',
        undo: 'Cancel',
    },
    notificationsHistory: {
        title: 'Notifications',
    },
    errorCode: {
        emailAreadyExist: 'There is already an account with this email address.',
        codeNotFound: 'Code not found.',
        codeNotExist: 'The code entered doesn’t exist.',
        codeAlreadyUsed: 'Code already in used.',
        userNotFound: 'User not found or inexistent.',
        notConfirmedEmail: 'The email address does not match your confirmation email address.',
        exceededMembers: 'Sorry, you have reached the maximum number of family members that can be added.',
        childIsTooYoung: 'We couldn’t add a child aged under the age of 6 months.',
        exceededAllergies: 'Maximum number of 5 allergies exceeded.',
        exceededIngredients: 'Maximum number of 5 ingredients exceeded.',
        tooYoung: 'You must be 18 or over to register.',
        weeklyRecipeNotFound: 'Recipe of the week not found.',
        codeGenerationNotFamilyLeader: 'You need to be the admin to generate the code.',
        codeGenerationImpossibleForFamilyLeader: 'An invitation code cannot be generated for an admin.',
        codeGenerationImpossibleForDifferentFamily: 'An invitation code cannot be generated for a different family.',
        planInCreation: 'Please wait for Planter to update the family plan before performing any other action.',
        PlanNotDeleted: 'The plan has not been deleted.',
        BirthdayShouldBeGreaterThanThePreviousOne: 'The new date of birth must be earlier than the one previously set',
    },
    changeMeal: {
        headerTitle: 'Create substitute meal',
        whomTitle: 'Who do you want to create %{meal} sostitutiv%{lastLetter} for?',
        assignedMealComponents: 'Members assigned %{meal}',
        chooseBalancedMeal: 'Choose one of these balanced meals or create a new one.',
        theBreakfast: 'Breakfast',
        theLunch: 'Lunch',
        theSnack: 'Morning snack',
        theDinner: 'Dinner',
        toBreakfast: 'with breakfast',
        toLunch: 'with lunch',
        toSnack: 'with the morning snack',
        toDinner: 'with dinner',
        lastFemaleLetter: 'a',
        lastMaleLetter: 'o',
        choose: 'Choose',
        createYourMeal: 'Create your meal',
        howNutrientsWorks: 'How it works',
        nutrientsInfo: 'The food group icons will gradually intensify in colour as you add recipes that fall within those categories.\n' +
            "Once the selected recipes make your meal balanced for the day, the icon's colour will fill up completely.\n" +
            'Otherwise, they will remain grey or partially coloured.',
        createOwnMealDescription: 'Select the recipes you want to add to your meal. Planter will help you create a balanced alternative.',
        yourMeal: 'Your meal',
        selectYourRecipe: 'Add a dish',
        mealCreated: 'Your meal has been created.',
        emptyNutrientTitle: 'Not enough',
        almostFullNutrientTitle: 'Hardly enough',
        fullNutrientTitle: 'Enough',
    },
    mobileViewWebInformation: {
        title: [
            'Planter is already',
            'creating',
            'your',
            'personalised',
            'meal plan!',
        ],
        subtitle: [
            '',
            'Download the app',
            ' to access your meal plan and shopping list, thousands of plant-based recipes and the chat with our nutritionists.',
        ],
        dowloadApp: 'You’re almost done! \nDownload the app and log in with your e-mail to view your meal plan and access all features.',
        manageSubscription: 'Manage your subscription'
    },
    recipePreview: {
        visualize: 'To view this and other recipes and discover all the other functions Planter has to offer, ',
        downloadApp: 'download the app',
    },
    inAppPurchase: {
        title: 'Join Planter',
        description: 'Planter is a paid-for service. The fee includes a personalised plan tailored to all your family’s needs.\nYou can add your family’s information and requirements later, for a maximum of',
        descriptionBold: '5 people',
        haveDiscount: 'Do you have a',
        discount: 'discount code',
        startTrial: 'Start your free trial',
        purchase: 'Purchase',
        trialDisclaimer: 'Your subscription will start when your 7-day free trial ends and then automatically renew. Don’t worry, you can cancel it any time. If you don’t want to continue, delete all your data',
        trialDisclaimerTap: 'by tapping here.',
        restorePurchase: 'Restore a purchase',
        termsAndCondition: 'Terms and conditions',
        labels: {
            threeMonths: '3 months',
            sixMonths: '6 months',
            annual: '12 months',
            lifetime: 'Forever',
            monthly: 'month',
            weekly: 'week',
            currencyEur: '€',
            currencyDollar: '$',
            cheapest: 'Best value',
        },
        introduction: {
            title: 'Here’s what you get',
            trialTitle: '7-day trial',
            trialDescription: 'Your subscription will automatically start when your initial 7-day free trial finishes but don’t worry, you can cancel it any time.',
            recipesTitle: 'Thousands of plant-based recipes',
            recipesDescription: 'Explained step by step – no special skills required!',
            planTitle: 'Personalised weekly meal plans',
            planDescription: 'A well-balanced meal plan tailored to all the family.',
            askTitle: 'Nutritionist on demand 7 days a week',
            askDescription: 'Need advice? Our team of experts is here to answer all your questions.',
            childrenTitle: 'Expert advice for kids',
            childrenDescription: 'Get detailed nutritional info for kids of all ages from weaning onwards.',
            listTitle: 'Shopping list',
            listDescription: 'Only buy what you really need and never run out of ingredients.',
            continueWithPurchase: 'Continue',
            warning: 'Warning',
            disclaimerNote: 'Planter is not meant to replace your GP’s advice. We recommend consulting your GP for any problems or queries about your health.',
            disclaimerHere: 'here',
            disclaimerScientifics: 'Check out the scientific basis behind your meal plan',
        },
        disclaimers: {
            title: 'About your plan',
            plan: 'Your Planter meal plan is',
            planEmphasized: 'generated in accordance with the very latest peer-reviewed guidelines',
            planFinal: 'for plant-based diets.',
            doctor: 'Dr. Silvia Goggi, a physician specialising in Food Science, created the Planter app algorithm by combining these guidelines with her clinical experience.',
            algorithm: 'This algorithm can select recipes, adjust the quantities of individual ingredients and create a well-balanced meal plan. Consult the articles about the original guidelines at the following links:',
            vegPlateLinkDesc: 'VegPlate: A Mediterranean-Based Food Guide for Italian Adult, Pregnant, and Lactating Vegetarians.',
            planningWellBalancedDesc: 'Planning Well-Balanced Vegetarian Diets in Infants, Children, and Adolescents: The VegPlate Junior.',
            veganNutritionDesc: 'Vegan Nutrition for Mothers and Children: Practical Tools for Healthcare Providers.',
            footerNote: 'Planter is not meant to be a replacement for your GP’s advice. We recommend consulting your GP about any health issues or concerns you may have.',
        },
        warnings: {
            expiredSub: 'We’re sorry but your subscription has expired. Reactivate it to continue.',
            issuePayment: 'A problem has occurred with your payment. Check the information and try again.',
            unknown: 'We’re sorry but a problem has occurred.',
        },
    },
    redeemCoupon: {
        title: 'Redeem a gift',
        subtitle: "Enter the coupon code you've received via email to claim your gift.",
        couponCode: 'Coupon Code',
        confirmBtn: 'Confirm',
        codeInputWrongFormat: 'The entered code is not valid.',
        error: {
            generic: 'An error occurred, please try again.',
            couponNotValid: 'This coupon could not be redeemed. Please try again or enter another coupon',
        },
    },
    redeemedCoupon: {
        title: ['Congratulations! Your', 'Gift', 'Has Been Redeemed.'],
        subtitle: 'Your subscription has been extended by the gifted time. You can check the updated renewal or expiration date in the Billing Details section.',
    },
    redeemCouponForbidden: {
        title: 'Redeem a gift',
        subtitle: 'Disable the automatic renewal of your subscription or free trial started with the App Store or Google Play and, once they have expired, come back to redeem your gift.',
    },
    introRedeemCoupon: {
        title: 'Redeem a gift',
        emailSection: {
            subtitle: 'Create an account or log in to an existing account to redeem your gift, get your personalised meal plan, thousands of plant-based recipes and advice from our team of nutritionists.',
        },
        countrySection: {
            subtitle: 'Enter your country of residence and accept the Privacy Policy and Terms and Conditions to proceed.',
        },
        redeemGiftSection: {
            subtitle: "Enter the coupon code you've received via email to claim your gift.",
            codeInputWrongFormat: 'The code entered is not valid.',
        },
    },
};
export default en;
